import { Box, LinearProgress, linearProgressClasses, styled } from "@mui/material";
import { Modal } from "components/Modal";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { selectorFinishModal } from "store/reducers/ModalsControl/Selectors";
import { AvaliacaoEnviadaModal } from "..";

interface IProps {
  percentage: number;
  onClose: () => void;
  onSuccess: () => void;
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: 'var(--primary-regular)',
  },
}));

const PreenchimentoParcial: React.FC<IProps> = ({
  percentage,
  onClose,
  onSuccess,
}) => {
  const [openAvaliacao, setOpenAvaliacao] = React.useState(false);
  const history = useHistory();
  const isOpen = useSelector(selectorFinishModal);
  const onFinish = () => {
    setOpenAvaliacao(false);
    history.push("/");
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        title="Deseja finalizar a avaliação?"
        size="sm"
        closeButtonText="Cancelar"
        submitButtonText="Enviar"
        onClose={onClose}
        onSubmit={() => {
          setOpenAvaliacao(true);
          onClose();
          onSuccess();
        }}
      >
        <div>
          Você concluiu <b>{percentage.toFixed()}%</b> da prova. <br /> Uma vez
          enviado, não tem como abrir novamente. Deseja enviar?
        </div>
        <Box sx={{ flexGrow: 1 }} style={{marginTop:30}}>
          <BorderLinearProgress variant="determinate" value={percentage} />
        </Box>
      </Modal>
      <AvaliacaoEnviadaModal
        percentage={percentage}
        isOpen={openAvaliacao}
        onClose={onFinish}
      />
    </>
  );
};
export default PreenchimentoParcial;
