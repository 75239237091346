import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";

import Header from "components/Header";
import AssignmentSubHeader from "./AssignmentSubHeader";
import AssignmentItem from "./AssignmentItem";
import AssignmentActions from "./AssignmentActions";
import { resetAssessmentState } from "store/reducers/Assessments/Actions";
import { IAssignmentItemProps } from "./AssignmentItem/types";
// import { updateListOfItemsStatus } from 'store/reducers/Assessments/Actions';
// import { StatusItem } from './AssignmentIndicatorTasks/types';
// import { updateItemStatus } from "store/reducers/Assessments/Actions";

/**
 * array criado para mostrar na ordem das questoes
 */
export const ALPHABET = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"];

export default function Assignment() {
  const history = useHistory();
  const dispatch = useDispatch();

  // const respostas = useSelector((state: any) => state.answers);

  const { assessments: examSelected, answers } = useSelector(
    (state: any) => state
  );

  useEffect(() => {
    if (!examSelected?.exam) {
      history.push("/");
    }
    // let items: number[] = respostas.answers.map(item => item.item);
    // if(items) dispatch(updateListOfItemsStatus(items));
    // eslint-disable-next-line
  }, [examSelected]);

  // useEffect(()=>{
  //   respostas.answers.forEach((item)=>{
  //     console.log(item.item)
  //     dispatch(updateItemStatus({
  //       id: item.item,
  //       status: "answered"
  //     }));
  //   })
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[])
  const currentTask = examSelected?.exam?.current_task;
  const selectedTasks = examSelected?.exam?.tasks;
  const taskData = selectedTasks[currentTask].task_data;
  return (
    <Box>
      <Header
        title={examSelected?.exam?.exam.description}
        onActionBtnArrowBack={() => {
          dispatch(resetAssessmentState());
          history.push("/");
        }}
        dividers
      />

      {/* <pre>
              {
                JSON.stringify(respostas.answers.map(item => typeof item.item), null, 4)
              }
            </pre> */}

      <Box style={{ marginBottom: "10rem", overflowWrap: 'break-word' }}>

        <AssignmentSubHeader
          indice={currentTask + 1}
          title={taskData.title}
          content={taskData.content}
        />

        {(taskData.items as any[]).map((item, index) => {
          const buscaResposta = answers.answers.filter((fil) => {
            return item.id === fil.item;
          });

          let type: IAssignmentItemProps["type"] =
            item.choices.length > 0 ? "select" : "open";
          if (item.is_hybrid) {
            type = "hibride";
          }

          return (
            <AssignmentItem
              key={`${index + 1}`}
              item={item.id}
              student_exam={examSelected.student_exam}
              order={`${item.number}`}
              text={item.content}
              options={item.choices}
              type={type}
              answer={buscaResposta[0]?.answer ?? ""}
              selectedItem={buscaResposta[0]?.choice}
              id_answer={buscaResposta[0]?.id ?? 0}
              subContents={taskData.subcontents}
            />
          );
        })}
      </Box>

      <AssignmentActions />
    </Box>
  );
}
