import { AxiosResponse } from "axios";
import { updateItemStatus } from "store/reducers/Assessments/Actions";
import {
  addStudentAnswer,
  setStudentAnswer,
  patchStudentAnswer,
} from "../Actions";
import {
  getStudentAnswersById,
  postStudentAnswers,
  updateStudentAnswers,
} from "./studentsanswers.service";
import { open_dialog } from "store/reducers/Loading/Actions";

function openLoading(dispatch: any) {
  dispatch(
    open_dialog({
      open: true,
      fullWidth: true,
      maxWidth: "sm",
      title:
        "Sua resposta está sendo salva. Por favor, aguarde alguns instantes.",
    })
  );
}

function closeLoading(dispatch: any) {
  dispatch(
    open_dialog({
      open: false,
      fullWidth: true,
      maxWidth: "sm",
      title: "",
    })
  );
}

/**
 *
 * @param props
 * @returns a list of student answers filtered by the student id
 */

export const getStudentAnswers = (props: any) => {
  return (dispatch: any) => {
    getStudentAnswersById(props.id, props.exam_id).then(
      (response: AxiosResponse<any>) => {
        // console.log(response.data.results[response.´data.results.length - 1])
        if (response.data.results && response.data.results.length > 0) {
          dispatch(
            addStudentAnswer(
              response.data.results[response.data.results.length - 1]
            )
          );
          // Muda o indicador para current ao selecionar questõa a ser respondida
          // dispatch(updateItemStatus({
          //   id: response.data.results[response.data.results.length - 1].item,
          //   status: "answered"
          // }));
        }
      }
    );
  };
};

/**
 *
 * @param props
 * @returns Envia as respostas do aluno para a API
 * -- OK
 */
export const submitStudentAnswers = ({ onError, onSuccess, ...props }: any) => {
  return async (dispatch: any) => {
    openLoading(dispatch);
    await postStudentAnswers(props)
      .then((response: AxiosResponse<any>) => {
        dispatch(addStudentAnswer(response.data));
        dispatch(updateItemStatus({ id: props.item, status: "answered" }));
        onSuccess();
      })
      .catch(() => {
        onError({ message: "Falha ao enviar resposta, tente novamente!" });
      })
      .finally(() => closeLoading(dispatch));
  };
};

/**
 *
 * @param props : { id, data }
 * @returns Atualiza a resposta submetida pelo aluno na API
 */
export const patchStudentAnsers = ({ onError, onSuccess, ...props }: any) => {
  return async (dispatch: any) => {
    openLoading(dispatch);
    await updateStudentAnswers(props.id, { ...props, })
      // await updateStudentAnswers('açoc=asd', { ...props }) // Linha com erro, para testar then-catch
      .then(({ data }: AxiosResponse<any>) => {
        dispatch(setStudentAnswer(data));
        dispatch(patchStudentAnswer(data));
        onSuccess();
      })
      .catch(() => {
        onError({ message:  "Falha ao enviar resposta, tente novamente!"});
      })
      .finally(() => closeLoading(dispatch));
  };
};

function ServiceException(this, message: string) {
  this.message = message;
  this.name = "ServiceException";
}
