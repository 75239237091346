import { FormEvent, useState } from "react";

import { useDispatch } from "react-redux";

import { toast } from "react-toastify";

import {
  Box,
  Button,
  TextField,
  Typography,
} from "@mui/material";

import SearchIcon from '@mui/icons-material/Search';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';

import { DialogConfirmSelectStudent } from "./components/DialogConfirmSelectStudent";
import Loading from 'components/Loading'
import { DialogSelect } from "./components/DialogSelect";
import { IListForFilter } from "./components/DialogSelect";

import * as LoadingActions from "store/reducers/Loading/Actions";

import AuthenticationServices from "store/reducers/Authentication/AsyncActions/authentication.services";
import * as GroupServices from 'store/reducers/Groups/AsyncActions/groups.service'

import { IRegisterProps } from "store/reducers/Authentication/AsyncActions/types";

import './styles.scss'

export function SelectStudent() {
  const dispatch = useDispatch()

  const [typeSearch, setTypeSearch] = useState<'identificador' | 'escola_turma_nome'>('identificador')

  const [isOpenSelectStudent, setIsOpenSelectStudent] = useState(false);
  const [isOpenDialogSelect, setIsOpenDialogSelect] = useState(false);

  const [typeSelect, setTypeSelect] = useState<'school' | 'class' | 'student'>('school')
  const [listItems, setListItems] = useState<IListForFilter[]>([])
  const [listSchools, setListSchools] = useState<IListForFilter[]>([])
  const [listClasses, setListClasses] = useState<IListForFilter[]>([])
  const [listStudents, setListStudents] = useState<IRegisterProps[]>([])

  const [schoolSelected, setSchoolSelected] = useState<IListForFilter>()
  const [classSelected, setClassSelected] = useState<IListForFilter>()
  const [fullNameStudent, setFullNameStudent] = useState('')
  const [username, setUserName] = useState("");
  
  const [studentSelected, setStudentSelected] = useState<IRegisterProps>();

  const findDiscentes = (e: FormEvent) => {
    e.preventDefault()

    let searchParams = {}

    if (typeSearch === 'identificador') {
      if (!username) {
        toast.warning('Necessário informar identificador.')
        
        return
      }

      searchParams = { profile__type: "student", username }
    } else {
      if (!schoolSelected) {
        toast.warning('Necessário selecionar a escola.')
  
        return
      }
  
      if (!classSelected) {
        toast.warning('Necessário selecionar a turma.')
        
        return
      }
  
      if (!fullNameStudent) {
        toast.warning('Necessário informar nome do aluno.')
        
        return
      }

      searchParams = { profile__group: classSelected.id, full_name: fullNameStudent }
    }

    dispatch(
      LoadingActions.open_dialog({
        open: true,
        fullWidth: true,
        maxWidth: "sm",
        title: "Carregando escolas, por favor aguarde...",
      })
    );

    AuthenticationServices.getUsersByParams(searchParams)
    .then(({ data }: any) => {
      if (data?.results.length > 0) {
        if (typeSearch === 'identificador') {
          setStudentSelected(data.results[0])
          setIsOpenSelectStudent(true)
        } else {
          if (data.results.length > 0 ) {
            const responseFormated = data.results.map(item => {
              return {
                id: item.id,
                name: `${item.first_name} ${item.last_name}`
              }
            })

            setListStudents(data.results)
            setListItems(responseFormated)
            setTypeSelect('student')
            
            setIsOpenDialogSelect(true)
          } else {
            toast.warning('Nenhum registro encontrado para esta busca.')
          }
        }
      } else {
        toast.error('Nenhum aluno com o identificador informado.')
      }

      dispatch(LoadingActions.close_dialog());
    })
    .catch(() => toast.error('Ocorreu um erro ao realizar a busca.'))
  };

  const getListGroupSchool = async () => {
    dispatch(
      LoadingActions.open_dialog({
        open: true,
        fullWidth: true,
        maxWidth: "sm",
        title: "Carregando escolas, por favor aguarde...",
      })
    );

    if (listSchools.length > 0) {
      setListItems(listSchools)
      setTypeSelect('school')
      
      dispatch(LoadingActions.close_dialog());
      
      setIsOpenDialogSelect(true)
    } else {
      await GroupServices.getGroup({ type: 'school' })
        .then(({ data }: any) => {
          if (data.length > 0) {
            const response = data as GroupServices.IGroup[];

            const responseFormated = response.map(item => {
              return {
                id: item.id,
                name: item.name
              }
            })
  
            setListSchools(responseFormated)
            setListItems(responseFormated)
            setTypeSelect('school')
            
            
            setIsOpenDialogSelect(true)
          } else {
            toast.warning('Nenhum registro encontrado para esta busca.')
          }
          
          dispatch(LoadingActions.close_dialog());
        })
        .catch(() => toast.error('Ocorreu um erro ao realizar a busca.'))
    }
  }

  const getListGroupClass = async () => {
    if (!schoolSelected) {
      toast.warning('Necessário selecionar a escola.')

      return
    }

    dispatch(
      LoadingActions.open_dialog({
        open: true,
        fullWidth: true,
        maxWidth: "sm",
        title: "Carregando turmas, por favor aguarde...",
      })
    );
    
    if (listClasses.length > 0) {
      setListItems(listClasses)
      setTypeSelect('class')
      
      dispatch(LoadingActions.close_dialog());
      
      setIsOpenDialogSelect(true)
    } else {
      await GroupServices.getGroup({ type: 'class', parent: schoolSelected.id })
        .then(({ data }: any) => {
          if (data.length > 0) {
            const response = data as GroupServices.IGroup[];

            const responseFormated = response.map(item => {
              return {
                id: item.id,
                name: item.name
              }
            })

            setListClasses(responseFormated)
            setListItems(responseFormated)
            setTypeSelect('class')
            
            setIsOpenDialogSelect(true)
          } else {
            toast.warning('Nenhum registro encontrado para esta busca.')
          }

          dispatch(LoadingActions.close_dialog());
        })
        .catch(() => toast.error('Ocorreu um erro ao realizar a busca.'))
    }
  }

  const onSelectSchoolOrClass = (typeSelect: 'school' | 'class' | 'student', item: IListForFilter) => {
    if (typeSelect === 'school') {
      setSchoolSelected(item)
    }

    if (typeSelect === 'class') {
      setClassSelected(item)
    }

    if (typeSelect === 'student') {
      setStudentSelected(listStudents?.find(student => student.id === item.id))
      setIsOpenSelectStudent(true)
    }
  }

  return (
    <Box className="content_select_student">
      <Typography variant="subtitle2">
        Para iniciar a transcrição é necessário informar o aluno. Selecione uma opção de busca:
      </Typography>

      <Box className="area_select_type_search">
        <Button
          variant={typeSearch === 'identificador' ? "contained" : 'text'}
          onClick={() => setTypeSearch('identificador')}
        >
          Identificador
        </Button>

        <Button
          variant={typeSearch === 'escola_turma_nome' ? "contained" : 'text'}
          onClick={() => setTypeSearch('escola_turma_nome')}
        >
          Escola, Turma e Nome
        </Button>
      </Box>
      
      <form onSubmit={findDiscentes} className="form_select_student">
        {
          typeSearch === 'identificador'
          ?
          <TextField
            variant="outlined"
            label="Identificador"
            placeholder="Digite o identificador do aluno desejado..."
            onChange={(e) => setUserName(e.target.value)}
            required
            fullWidth
          />
          :
          <Box className="area_search_by_school_class_fullname">
            <Button
              variant={ schoolSelected ? 'contained' : 'outlined' }
              color="primary"
              startIcon={ schoolSelected ? <DoubleArrowIcon /> : <TouchAppIcon /> }
              onClick={getListGroupSchool}
            >
              { schoolSelected ? schoolSelected.name : 'Selecionar Escola' }
            </Button>

            <Button
              variant={ classSelected ? 'contained' : 'outlined' }
              color="primary"
              startIcon={ classSelected ? <DoubleArrowIcon /> : <TouchAppIcon /> }
              onClick={getListGroupClass}
            >
              { classSelected ? classSelected.name : 'Selecionar Turma' }
            </Button>

            <TextField
              variant="outlined"
              label="Nome do aluno:"
              placeholder="Digite o nome do aluno"
              value={fullNameStudent}
              onChange={(e) => setFullNameStudent(e.target.value)}
              required
            />
          </Box>
        }
        
        <Button
          type="submit"
          variant="contained"
          startIcon={<SearchIcon />}
        >
          Buscar Aluno
        </Button>
      </form>

      <DialogSelect
        open={isOpenDialogSelect}
        setOpen={setIsOpenDialogSelect}
        typeSelect={typeSelect}
        listItems={listItems}
        onSelectItem={onSelectSchoolOrClass}
      />

      <DialogConfirmSelectStudent
        open={isOpenSelectStudent}
        setOpen={setIsOpenSelectStudent}
        selectedStudent={studentSelected}
      />

      <Loading />
    </Box>
  );
};
