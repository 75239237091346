import React from "react";
import ReactPlayer from "react-player";

export function Video({src}) {
  return (
    <div className="flex place-content-center w-full my-4">
      <ReactPlayer
        className="place-self-center"
        url={src}
        controls
      />
    </div>
  );
}
