import { createReducer } from "@reduxjs/toolkit";

import { IOpenDialogLoading } from "store/reducers/Loading/Actions/types";

import { open_dialog, close_dialog } from "./Actions";

const INITIAL_STATE: IOpenDialogLoading = {
  open: false,
  title: "",
};

export default createReducer<IOpenDialogLoading>(
  INITIAL_STATE as IOpenDialogLoading,
  {
    [open_dialog.type]: (state, action) => ({ ...state, ...action.payload }),
    [close_dialog.type]: () => INITIAL_STATE,
  }
);
