import { createReducer } from "@reduxjs/toolkit";
import { setFinishModalValue, toggleFinishModal } from "./Actions";

export interface StateModalsControl {
  finishModal: boolean;
}

const INITIAL_STATE: StateModalsControl = {
  finishModal: false,
};

export default createReducer(INITIAL_STATE, (builder) => {
  builder.addCase(toggleFinishModal, (state) => {
    return { ...state, finishModal: !state.finishModal };
  });
  builder.addCase(setFinishModalValue, (state, { payload }) => {
    return { ...state, finishModal: payload };
  });
});
