import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import AssignmentTurnedInOutlined from "@mui/icons-material/AssignmentTurnedInOutlined";
import CollectionsBookmarkOutlined from "@mui/icons-material/CollectionsBookmarkOutlined";
import EventBusyOutlined from "@mui/icons-material/EventBusyOutlined";

import NoItems from "./NoItems";
import ItemEvidencesSecondary from "./ItemEvidencesSecondary";
import Loading from "components/Loading";

import { formatDate, isDateActived } from "helpers/formatDate";

import { listExamsByUser } from "store/reducers/Exams/AsyncActions";

import "./styles.scss";
import { IRegisterProps } from "store/reducers/Authentication/AsyncActions/types";
import { IAssessmentExam } from "store/reducers/Assessments/Actions/types";

export default function ListEvidences() {
  const dispath = useDispatch();

  const { exams, authentication, application } = useSelector(
    (state: any) => state
  );

  const [listExamsActived, setListExamsActived] = useState<any[]>([]);
  const [listExamsFinished, setListExamsFinished] = useState<any[]>([]);
  const [listExamsExpired, setListExamsExpired] = useState<any[]>([]);

  useEffect(() => {
    let idUser: number = 0;
    if (application.userApplication) {
      idUser = application.userApplication.id;
      dispath(listExamsByUser(idUser));
    }
  }, [dispath, authentication.user.idUser, application.userApplication]);

  useEffect(() => {
    if (Array.isArray(exams?.results) && exams?.results?.length > 0) {
      const listActived = exams.results.filter(
        (exam: any) =>
          !exam.ended_at && isDateActived(exam.assessment_data.ends_at)
      );
      const listFinished = exams.results.filter((exam: any) => exam.ended_at);
      const listExpired = exams.results.filter(
        (exam: any) =>
          !exam.ended_at && !isDateActived(exam.assessment_data.ends_at)
      );

      setListExamsActived(listActived);
      setListExamsFinished(listFinished);
      setListExamsExpired(listExpired);
    }
  }, [exams]);

  const getExamName = (exams: IAssessmentExam[], idExam: number): string => {
    let title = "";
    const exam = exams.find((i) => i.exam === idExam);
    if (exam) title = exam.title as string;
    return title as string;
  };

  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <Box className="header_page_modules">
            {/* <Box className="main">
              <Box className="container">
                <Box />
                <Box />
                <Box />
              </Box>
            </Box> */}

            <Box className="area_title_page_modules">
              <Typography className="title_page_evidences">
                Avaliações
              </Typography>
              <Typography className="subtitle_page_evidences">
                Veja as avaliações disponíveis e realizadas por você
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Grid container className="step_module">
        <Grid item xs={12}>
          <Typography className="title_module">
            <CollectionsBookmarkOutlined /> Avaliações Ativas
          </Typography>
        </Grid>

        <Grid item xs={12}>
          {listExamsActived.length > 0 ? (
            <>
              {listExamsActived.map((exam: any) => (
                <ItemEvidencesSecondary
                  key={exam.id}
                  type="actived"
                  examName={getExamName(exam.assessment_data.exams, exam.exam)}
                  idAssessment={exam.assessment}
                  idExam={exam.exam}
                  title={exam.assessment_data?.title ?? "(Sem título)"}
                  progress={exam.exam_data.percentProgress}
                  timeStart={formatDate({
                    date: exam.assessment_data.starts_at,
                    dateSeparator: ".",
                  })}
                  timeTerm={formatDate({
                    date: exam.assessment_data.ends_at,
                    withHour: true,
                    dateSeparator: ".",
                  })}
                  code={exam.code}
                />
              ))}
            </>
          ) : (
            <NoItems />
          )}
        </Grid>
      </Grid>

      <Grid container className="step_module">
        <Grid item xs={12}>
          <Typography className="title_module">
            <CollectionsBookmarkOutlined /> Avaliações Realizadas
          </Typography>
        </Grid>

        <Grid item xs={12}>
          {listExamsFinished.length > 0 ? (
            <>
              {listExamsFinished.map((exam: any) => (
                <ItemEvidencesSecondary
                  key={exam.id}
                  type="finished"
                  examName={getExamName(exam.assessment_data.exams, exam.exam)}
                  idAssessment={exam.assessment}
                  idExam={exam.exam}
                  title={exam.assessment_data?.title ?? "(Sem título)"}
                  timeFinished={formatDate({
                    date: exam.ended_at,
                    dateSeparator: ".",
                  })}
                  progress={exam.exam_data.percentProgress}
                  code={exam.code}
                />
              ))}
            </>
          ) : (
            <NoItems />
          )}
        </Grid>
      </Grid>

      <Grid container className="step_module">
        <Grid item xs={12}>
          <Typography className="title_module">
            <EventBusyOutlined /> Avaliações Expiradas
          </Typography>
        </Grid>

        <Grid item xs={12}>
          {listExamsExpired.length > 0 ? (
            <>
              {listExamsExpired.map((exam: any) => (
                <ItemEvidencesSecondary
                  key={exam.id}
                  type="expired"
                  examName={getExamName(exam.assessment_data.exams, exam.exam)}
                  idAssessment={exam.assessment}
                  idExam={exam.exam}
                  title={exam.assessment_data?.title ?? "(Sem título)"}
                  timeTerm={formatDate({
                    date: exam.assessment_data.ends_at,
                    withHour: true,
                    dateSeparator: ".",
                  })}
                  progress={exam.exam_data.percentProgress}
                  code={exam.code}
                />
              ))}
            </>
          ) : (
            <NoItems />
          )}
        </Grid>
      </Grid>

      {/* <Loading /> */}
    </Container>
  );
}
