import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import CheckCircleOutlined from "@mui/icons-material/CheckCircleOutlined";
import Close from "@mui/icons-material/Close";
import Edit from "@mui/icons-material/Edit";

import { MarkdownConvert } from "components/MarkdownConvert";

import {
  patchStudentAnsers,
  submitStudentAnswers,
} from "store/reducers/StudentAnsers/AsyncActions";

import { updateItemStatus } from "store/reducers/Assessments/Actions";

import { StatusItem } from "../AssignmentIndicatorTasks/types";
import { IAssignmentItemProps, TOptions } from "./types";

import { ALPHABET } from "..";

import { toast } from "react-toastify";
import AssignmentSubHeader from "../AssignmentSubHeader";
import "./styles.scss";
import { IStudentAnswer } from "store/reducers/StudentAnsers/Actions/types";

export default function AssignmentItem({
  id_answer,
  order,
  text,
  type = "open",
  options,
  item,
  student_exam = 1,
  answer,
  subContents = [],
  selectedItem,
}: IAssignmentItemProps) {
  const dispatch = useDispatch();

  const [canceled, setCanceled] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [studentGuess, setStudentGuess] = useState<number | null>(null);
  const [studentTextAnswer, setStudentTextAnswer] = useState<string | null>("");

  const isOpen = type === "open";
  const isHibride = type === "hibride";

  const hasAnswered = !!id_answer;
  const notAnswered = !answer && !selectedItem;
  const isHibrideAnswered = isHibride && selectedItem && answer;
  const isOpenQuestion = answer && isOpen;
  const isMultipleChoiceAnswered = selectedItem && !isOpen && hasAnswered;

  const subContentData = subContents.find((subContent) => {
    return subContent.position === Number(order);
  });

  const updateState = (id: number, status: StatusItem) => {
    dispatch(updateItemStatus({ id, status }));
  };

  const handleSubmitAnswer = async () => {
    if (editMode) {
      toast.warn("Este item já foi respondido.");
      return;
    }

    if (!studentTextAnswer && !studentGuess) {
      toast.warn("Insira sua resposta");
      return;
    }

    dispatch(
      submitStudentAnswers({
        ...makeAnswerData(),
        onError: (error = { message: "" }) => {
          toast.error(error.message);
        },
        onSuccess: () => {
          // Depois de enviar para a API altera o status para respondido
          toast.success("Resposta enviada com sucesso!");
          updateState(item, "answered");
          setEditMode(true);
        },
      })
    );
  };

  const handleClickedAlter = () => {
    setEditMode(false);

    setTimeout(() => {
      const getInput = document.getElementById(`response${item}`);

      if (getInput) {
        getInput.focus();
      }
    }, 3000);
  };

  const handleUpdateAnswer = async () => {
    if (!studentTextAnswer && !studentGuess) {
      toast.warn("Insira sua resposta");
      return;
    }

    dispatch(
      patchStudentAnsers({
        ...makeAnswerData(),
        onError(error = { message: "" }) {
          toast.error(error.message);
          console.log("SAMPLE");
          console.log(error.message);
        },
        onSuccess() {
          // Depois de enviar para a API altera o status para respondido
          toast.success("Resposta enviada com sucesso!");
          updateState(item, "answered");
          setEditMode(true);
        },
      })
    );
  };

  const handleCancelAlterAnswer = () => {
    setEditMode(true);
    setCanceled((oldValue) => !oldValue);
  };

  const makeAnswerData = () => {
    const answerData: IStudentAnswer = {
      item,
      student_exam,
      id: id_answer,
    };
    if (studentTextAnswer) {
      answerData.answer = studentTextAnswer;
    }
    if (studentGuess) {
      answerData.choice = studentGuess;
    }

    return answerData;
  };

  useEffect(() => {
    setEditMode(!!id_answer);

    if (notAnswered) {
      setStudentTextAnswer("");
      setStudentGuess(null);
      setEditMode(false);
      return;
    }
    if (isHibrideAnswered) {
      setStudentTextAnswer(answer.toString());
      setStudentGuess(selectedItem);
    }
    if (isOpenQuestion) {
      setStudentTextAnswer(answer.toString());
    }
    if (isMultipleChoiceAnswered) {
      setStudentGuess(selectedItem);
    }
    //eslint-disable-next-line
  }, [answer, item, canceled]);

  // useEffect(()=>{
  //   /**
  //    * 1. Obtém o id do item
  //    * 2. Busca na API a resposta do item
  //    * 3. Adiciona resposta na Store
  //    */
  //   dispatch(
  //     getStudentAnswers({id: item})
  //   );

  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[])

  useEffect(() => {
    if (selectedItem) setStudentGuess(selectedItem);
  }, [selectedItem]);

  return (
    <>
      {subContentData && (
        <div className="sub_content">
          <AssignmentSubHeader content={subContentData?.content} />
        </div>
      )}

      <Box className="assignment_item">
        <Box className="header_item">
          <Typography className="order">{order}</Typography>

          <Box className="text">
            <MarkdownConvert textInMarkdown={text} />
          </Box>
        </Box>

        {(!isOpen || isHibride) && (
          <>
            {options?.map((op: TOptions, i) => (
              <Button
                key={i + 1}
                onClick={() => {
                  if (!editMode) {
                    setStudentGuess(op.id);
                  }
                }}
                onFocus={(e) => {
                  updateState(item, "current");
                }}
                onBlur={(e) => {
                  updateState(item, "unanswered");
                }}
                title={op.id === selectedItem ? "Resposta salva" : ""}
                fullWidth
                disabled={editMode}
                className={`btn_choice 
                  ${op.id === studentGuess && !editMode && "checked"} 
                  ${editMode && "disabled"}
                  ${op.id === selectedItem && "answered"}
                `}
              >
                <Avatar>{ALPHABET[i]}</Avatar>
                <MarkdownConvert textInMarkdown={op.content} />
              </Button>
            ))}
          </>
        )}

        {(isOpen || isHibride) && (
          <TextField
            id={`response${item}`}
            variant="outlined"
            label="Resposta:"
            fullWidth
            multiline
            minRows={3}
            maxRows={3}
            className="textarea_item_task"
            value={studentTextAnswer}
            onFocus={() => {
              updateState(item, "current");
            }}
            onBlur={() => {
              updateState(item, "unanswered");
            }}
            onChange={(e) => {
              if (!editMode) {
                setStudentTextAnswer(e.target.value);
              }
            }}
            disabled={editMode}
          />
        )}

        {!editMode ? (
          <Box display="flex" alignItems="center" gap={1}>
            <Button
              variant="contained"
              color={hasAnswered ? 'secondary' : 'primary'}
              fullWidth
              disableElevation
              onClick={hasAnswered ? handleUpdateAnswer : handleSubmitAnswer}
              title={hasAnswered ? "Alterar Resposta" : "Responder"}
            >
              {hasAnswered ? "Alterar Resposta" : "Responder"}
            </Button>

            {hasAnswered && (
              <Button
                variant="contained"
                color="secondary"
                disableElevation
                onClick={handleCancelAlterAnswer}
                title="Cancelar Alteração"
              >
                <Close />
              </Button>
            )}
          </Box>
        ) : (
          <Box className="area_answered_edit">
            <Typography className="item_checked">
              <CheckCircleOutlined />
              Questão respondida
            </Typography>

            <Button
              variant="text"
              startIcon={<Edit />}
              onClick={() => {
                updateState(item, "unanswered");
                handleClickedAlter();
              }}
            >
              Alterar
            </Button>
          </Box>
        )}
      </Box>
    </>
  );
}
