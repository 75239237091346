import React from "react";
import { InlineMath, BlockMath } from "react-katex";
import "katex/dist/katex.min.css";

export function Equacao({ expressao="" }) {
  const isBlock = expressao.startsWith("$$") && expressao.endsWith("$$");
  const isInline = expressao.startsWith("$") && expressao.endsWith("$");
  const mathExpression = isBlock || isInline ? expressao.slice(isBlock ? 2 : 1, isBlock ? -2 : -1) : null;

  if (isBlock) {
    return <BlockMath>{mathExpression}</BlockMath>;
  } else if (isInline) {
    return <InlineMath>{mathExpression}</InlineMath>;
  } else {
    return (
      <>
        <br />
        <br />
        <span className="text-bold text-red text-center">
          {`Use '$sua-expressão-katex$' para exibir em linha ou
          '$$sua-expressão-katex$$' para exibir em bloco.`}
        </span>
        <br />
        <br />
      </>
    );
  }
}
