import React, { useEffect } from "react";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { Box, Button, Grid, IconButton } from "@mui/material";
import { Property } from "csstype";
import { DateTime } from "luxon";
import { useDispatch, useSelector } from "react-redux";

import api from "services/api";
import getConclusionPercentage from "helpers/getConclusionPercentage";
import { AssessmentResourceType } from "store/reducers/Assessments";
import { setCurrentExamTask } from "store/reducers/Assessments/Actions";
import { IExam, ITaskExam } from "store/reducers/Assessments/Actions/types";
import { setFinishModalValue } from "store/reducers/ModalsControl/Actions";
import { PreenchimentoParcial } from "../../../components/Modals";
import AssignmentIndicatorTasks from "../AssignmentIndicatorTasks";
import "./styles.scss";

const DirectionButton: React.FC<{
  direction?: "back" | "forward";
  onClick?: () => void;
  color?: Property.Color;
  bgColor?: Property.BackgroundColor;
  style?: React.CSSProperties;
}> = ({
  bgColor = "var(--primary-lighter)",
  color = "var(--primary-regular)",
  direction = "back",
  onClick = () => {},
  style = {},
}) => (
  <Button
    name="preview"
    style={{ color, backgroundColor: bgColor, maxWidth: 120, ...style,  }}
    onClick={onClick}
    
   >
    {direction === "back" ? <ArrowBack /> : <ArrowForward />}
   </Button>
);

export default function FooterActions() {
  const dispatch = useDispatch();
  const assessmentState = useSelector(
    (state: any) => state.assessments
  ) as AssessmentResourceType;
  const [percentage, setPercentage] = React.useState<number>(0);

  useEffect(() => {
    setPercentage(getConclusionPercentage(assessmentState));
  }, [assessmentState]);

  const updateTaskIndex = (add: number) => {
    const currentExam: IExam | undefined = assessmentState.exam;
    if (currentExam !== undefined) {
      const tasks: ITaskExam[] = currentExam.tasks;
      const adjustedIndexTask = (currentExam.current_task as number) + add;
      if (adjustedIndexTask >= 0 && adjustedIndexTask < tasks.length)
        dispatch(setCurrentExamTask(adjustedIndexTask));
    }
  };

  return (
    <Box className="area_footer_actions" style={{ maxWidth: "900px" }}>
      <AssignmentIndicatorTasks />
      <PreenchimentoParcial
        percentage={percentage}
        onClose={async () => {
          dispatch(setFinishModalValue(false));
        }}
        onSuccess={async () => {
          if (assessmentState.exam?.id) {
            const { student_exam: id } = assessmentState;
            await api
              .patch(`assessments/studentexams/${id}/`, {
                ended_at: DateTime.now().setZone("America/Fortaleza").toISO(),
              })
              .then(() => {
                dispatch(setFinishModalValue(false));
              })
              .catch(console.error);
          }
        }}
      />
      <Grid container flexDirection="row-reverse" spacing={1} sx={{ padding: 2, textAlign: "center" }}>
        <Grid item xs={6} md={8}>
          <Button
            sx={{
              fontWeight: 700,
            }}
            variant="contained"
            size="large"
            fullWidth
            onClick={() => {
              dispatch(setFinishModalValue(true));
            }}
          >
            Finalizar
          </Button>
        </Grid>
        <Grid item xs={6} md={4} sx={{ minWidth: "88px" }}>
          <DirectionButton
            style={{ marginRight: "2px" }}
            direction="back"
            onClick={() => {
              updateTaskIndex(-1);
            }}
          />
          <DirectionButton
            style={{ marginLeft: "2px" }}
            direction="forward"
            onClick={() => {
              updateTaskIndex(1);
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
