import { useSelector } from "react-redux";

import { Button, Typography } from '@mui/material';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz'

import { SelectStudent } from "./SelectStudent";

import { useDispatch } from 'react-redux'

import ListEvidences from ".";
import { resetUserApplication } from "store/reducers/Application/actions";

export function EvidencesRouter() {
  const dispatch = useDispatch()
  const { application, authentication } = useSelector((state: any) => state);

  if (!application.userApplication) return <SelectStudent />
  
  function handleChangeUserApplication() {
    dispatch(resetUserApplication())
  }

  return (
    <>
      {
        application.userApplication.id !== authentication.user.idUser &&
        <div className="area_change_user_application">
          <Typography variant="subtitle2">Para modificar o usuário selecionado, clique no botão: </Typography>

          <Button
            variant="contained"
            size="small"
            startIcon={<SwapHorizIcon />}
            onClick={handleChangeUserApplication}
          >
            Alterar Usuário
          </Button>
        </div>
      }

      <ListEvidences />
    </>
  );
};
