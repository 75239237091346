import ReactDOM from "react-dom";
import App from "./App";
import "./styles/global.scss";

import { ssoConfig } from "./ssoConfig";
import { setAuthConfig } from "@cenpe/auth";

setAuthConfig(ssoConfig);

ReactDOM.render(<App />, document.getElementById("root"));
