import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import ArrowBackRounded from '@mui/icons-material/ArrowBackRounded';

import { IHeaderProps } from './types';

import './styles.scss';

export default function Header({
    title,
    subtitle,
    dividers,
    onActionBtnArrowBack
}: IHeaderProps) {
    return (
        <Box className="area_header_assignment">
            <Box className="subheader">
                <div onClick={onActionBtnArrowBack} className="btn_arrow_back_header">
                    <ArrowBackRounded htmlColor="#1D2432" />
                </div>

                <Box>
                    <Typography className="title_header">{title}</Typography>
                    <Typography className="subtitle_header">{subtitle}</Typography>
                </Box>
            </Box>

            { dividers && <Box className="dividers_header"/> }
        </Box>
    )
}