import api from "services/api";

export interface IGroup {
    id: number;
    children: number[];
    type: "school" | "class";
    name: string;
    code: string;
    parent: number | undefined;
    serie: number | undefined;
    shift: number | undefined;
}

interface IGetGroupSearchParams {
    type: 'school' | 'class';
    parent?: undefined | number; 
}

export const getGroup = async (searchParams: IGetGroupSearchParams) => {
    return await api.get('/groups/groups/', { params: searchParams });
}