'use client'

import { useEffect, useRef, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faPause, faVolumeMute, faVolumeHigh, faEllipsisVertical, faForward } from "@fortawesome/free-solid-svg-icons";
import Slider from '@mui/material/Slider';

function formatDurationDisplay(duration) {
    const min = Math.floor(duration / 60);
    const sec = Math.floor(duration - min * 60);

    const formatted = [min, sec].map((n) => (n < 10 ? '0' + n : n)).join(':');

    return formatted;
}

export function Audio({src}) {

    const audioRef = useRef(null)

    const [isPlaying, setIsPlaying] = useState(false)
    const [isMute, setIsMute] = useState(false)
    const [isOptionsVisible, setIsOptionsVisible] = useState(false);


    const [duration, setDuration] = useState(0);
    const [currentProgress, setcurrentProgress] = useState(0);

    const durationDisplay = formatDurationDisplay(duration);
    const elapsedDisplay = formatDurationDisplay(currentProgress);

    function toggleOptionsVisibility() {
        setIsOptionsVisible(!isOptionsVisible);
    }

    function increasePlaybackSpeed() {
        if (audioRef.current.playbackRate >= 2) {
            audioRef.current.playbackRate = 1;
        }
        else audioRef.current.playbackRate += 0.5;
    }

    function moreOptions() {
        return (
            <div>
                {isOptionsVisible && (

                    <button onClick={increasePlaybackSpeed} className=" float-right flex gap-2 items-center bg-white shadow-xl rounded-md text-xs p-4">
                        <FontAwesomeIcon icon={faForward} style={{ color: "#3F26D9" }} className="h-4" />
                        <span>Velocidade de reprodução {audioRef.current.playbackRate}x</span>
                    </button>
                )}
            </div>
        );
    }

    function setPlayingState(state) {
        setIsPlaying(state)
    }

    function toggleIsPlaying() {
        setIsPlaying(!isPlaying)
    }

    function toggleIsMute() {
        setIsMute(!isMute)
    }


    useEffect(() => {
        if (!audioRef.current) {
            return;
        }
        isPlaying ? audioRef.current.play() : audioRef.current.pause()

        audioRef.current.volume = isMute ? 0 : 1;

    }, [isPlaying, isMute])




    return (
        <div className="comp mt-2 mb-2">
            <div className="bg-slate-100 flex flex-row items-center gap-4 p-4 rounded-md w-11/12">
                {isPlaying ? (
                    <button className="flex items-center" onClick={toggleIsPlaying}><FontAwesomeIcon icon={faPause} style={{ color: "#3F26D9", }} className="h-5" /></button>
                ) : (
                    <button className="flex items-center" onClick={toggleIsPlaying}><FontAwesomeIcon icon={faPlay} style={{ color: "#3F26D9", }} className="h-5" /></button>
                )
                }

                <div className="items-center flex flex-row">
                    <span className="text-xs items-center w-20">
                        {elapsedDisplay} / {durationDisplay}
                    </span>
                </div>
                <audio
                    src={src}
                    autoPlay={true}
                    ref={audioRef}
                    volume={isMute ? 0 : 1}
                    onPlay={() => setPlayingState(true)}
                    onPause={() => setPlayingState(false)}
                    onDurationChange={(e) => setDuration(e.currentTarget.duration)}
                    onTimeUpdate={(e) => setcurrentProgress(e.currentTarget.currentTime)}
                />

                <div className="flex items-center w-full">
                    <div className="flex items-center w-full bg-white rounded-full cursor-pointer h-1 hover:h-2 dark:bg-white">
                        <Slider
                            aria-label="time-indicator"
                            size="small"
                            value={currentProgress}
                            min={0}
                            step={1}
                            max={duration}
                            onChange={(_, value) => {
                                if (!audioRef.current) return;
                                audioRef.current.currentTime = value;
                                setcurrentProgress(value);
                            }}
                            sx={{
                                color: "#3F26D9", // Define a cor desejada
                                '& .MuiSlider-track': {
                                    border: 'none',
                                },
                                // "& .MuiSlider-rail": {
                                //     backgroundColor: "#FFFFFF",
                                // },
                                "&:hover": {
                                    height: 5,
                                },
                                '& .MuiSlider-thumb': {
                                    width: 15,
                                    height: 15,
                                    backgroundColor: '#3F26D9',
                                    '&:before': {
                                        boxShadow: '2px 2px 8px rgba(0,0,0,0.4)',
                                    },
                                    '&:hover, &.Mui-focusVisible, &.Mui-active': {
                                        boxShadow: 'none',
                                        height: 20,
                                        width: 20,
                                    },

                                },
                            }}
                        />
                    </div>
                </div>
                {isMute ? (
                    <button className="flex items-center" onClick={toggleIsMute}><FontAwesomeIcon icon={faVolumeMute} style={{ color: "#3F26D9", }} className="h-5" /></button>
                ) : (
                    <button className="flex items-center" onClick={toggleIsMute}><FontAwesomeIcon icon={faVolumeHigh} style={{ color: "#3F26D9", }} className="h-5" /></button>
                )
                }

                <button className="flex items-center" onClick={toggleOptionsVisibility}>
                    <FontAwesomeIcon icon={faEllipsisVertical} style={{ color: "#3F26D9" }} className="h-5" />
                </button>
            </div>
            {moreOptions()}
        </div>
    )
}