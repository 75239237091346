import {
	Box,
  Breakpoint,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,  
} from "@mui/material";
import React from "react";
import { CancelButton } from "./components/CancelButton";
import CloseIcon from "@mui/icons-material/Close";

import "./style.scss";

interface IProps {
  children?: React.ReactNode;
  isOpen: boolean;
  title: string;
  withAction?: boolean;
  closeButtonText?: string;
  submitButtonText?: string;
  onClose?: () => void;
  onSubmit?: () => void;
  size?: Breakpoint | false;
  disableSubmitButton?: boolean;
  fullScreen?: boolean;
}



export const Modal: React.FC<IProps> = ({
  isOpen,
  children,
  title,
  size,
  closeButtonText = "Cancelar",
  submitButtonText = "Cadastrar",
  withAction = true,
  disableSubmitButton = false,
  onClose,
  onSubmit,
  fullScreen = false,
}) => {
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  function executeOnClose() {
    if (onClose) onClose();
  }

  return (
    <Dialog
      className="caModalAvaliacaoEnviada"
      open={open}
      fullWidth
      style={{ fontFamily: "Quicksand", margin: "-25px" }}
      maxWidth={size}
      id="ceModal"
      onClose={() => {
        setOpen(false);
        executeOnClose();
      }}
      fullScreen={fullScreen}
    >
      {/* titúlo */}
      <DialogTitle
        sx={{
          textAlign: "left",
          fontFamily: "Poppins",
          fontSize: 16,
          fontWeight: 700,
        }}
      >
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[800],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {/* conteúdo */}
      <DialogContent style={{ fontWeight: 500, marginTop: 10 }}>
        <div className="titleModal">{title}</div>
        <div className="textModal">{children}</div>
        
      </DialogContent>

      {/* botões */}
      {withAction && (
        <DialogActions sx={{ display: "flex" }}>
          <div
            className="ceModalAction"
            style={{ marginLeft: 10, marginRight: 10, marginBottom: 15 }}
          >
            <div>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  setOpen(false);
                  executeOnClose();
                }}
              >
                {closeButtonText}
              </Button>
            </div>
            <div>
              <Button
                variant="contained"
                disabled={disableSubmitButton}
                onClick={() => {
                  setOpen(false);
                  if (onSubmit) onSubmit();
                }}
              >
                {submitButtonText}
              </Button>
            </div>
          </div>
        </DialogActions>
      )}
    </Dialog>
  );
};
