import { createAction } from "@reduxjs/toolkit";

import {
  IStudentExamPaginate,
  IStudentExam,
  IAssessmentExam,
} from "store/reducers/Assessments/Actions/types";

export const list_by_user = createAction<IStudentExamPaginate>("LIST_BY_USER");
export const set_exam_data = createAction<IAssessmentExam>("SET_EXAM_DATA");
export const update_item_results_studentexams = createAction<IStudentExam>(
  "UPDATE_ITEM_RESULTS_STUDENTEXAMS"
);
