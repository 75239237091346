import { createReducer } from "@reduxjs/toolkit";
import { StatusItem } from "pages/Assignment/AssignmentIndicatorTasks/types";
import {
  getExams,
  getExamByIndex,
  getCurrentExamIndex,
  setCurrentExamIndex,
  getExamTasks,
  getCurrentExamTask,
  setCurrentExamTask,
  setExams,
  setExam,
  getExam,
  showAssessment,
  updateAssessmentState,
  updateItemStatus,
  resetAssessmentState,
  updateListOfItemsStatus,
  //
} from "./Actions";
import { IExam } from "./Actions/types";

/**
 * Reducer para o Exam a partir do retorno da API, por hora implementei este objeto reduzido
 * para otimizar o código
 */
export interface AssessmentResourceType {
  student_exam: number;
  exams?: IExam[];
  exam?: IExam;
  currentExam?: number | undefined;
}

const INITIAL_VALUES: AssessmentResourceType = {
  student_exam: 0,
  exams: [
    {
      id: 0,
      tasks: [],
      // student_exams: [],
      code: undefined,
      description: undefined,
      knowledge_area: undefined,
      created_by: undefined,
      published: false,
      created_at: undefined,
      modified_at: undefined,
      current_task: null,
    },
  ],
  currentExam: undefined,
};

export default createReducer(INITIAL_VALUES, {
  [resetAssessmentState.type]: (state) => ({ ...INITIAL_VALUES }),
  /** atualiza todo o estado da store */
  [updateAssessmentState.type]: (state, action) => action.payload,
  /** Retorna todos os exams presentes na Store */
  [getExams.type]: (state) => state.exams,
  /** Seta todos os exams a partir da Api */
  [setExams.type]: (state, action) => ({ ...state, exams: action.payload }),

  /** Retorna o exam obtido a partir da Store
   * TODO: revisar Reducer
   */
  [getExam.type]: (state) => state.exam,

  /*Adiciona o exam obtido a partir da Store
   * TODO: revisar Reducer
   */
  [setExam.type]: (state, action) =>
    (state = { ...state, exam: action.payload }),

  /** Retorna um Exam específico, no índice passado no payload
   * TODO: revisar reducer
   */
  [getExamByIndex.type]: (state, action) => {
    state.exams!.find((exam) => exam.id === action.payload.id);
  },

  /** Retorna o índice do Exam atual
   * TODO: revisar reducer
   */
  [getCurrentExamIndex.type]: (state) => state.currentExam,

  /** Define um novo índice para o Exam atual 
   * TODO: verificar a implementação do reducer
  [setCurrentExamIndex.type]: (state, action) => {
    if (action.payload.index !== undefined) {
      if (
        action.payload.index >= 0 &&
        action.payload.index <= state.exams.length
      ) {
        return (state.currentExam = action.payload.index);
      }
    }
    return -1;
  },
  */

  /** Define um novo índice para o Exam atual */
  [setCurrentExamIndex.type]: (state, action) => {
    const index = action.payload;
    if (index !== undefined) {
      if (index >= 0 && index <= state.exams!.length) {
        state.currentExam = index;
      }
    }
  },

  /**
   * Dica: o reducer abaixo sempre irá retornar as tasks do Exam atual
   * TODO: revisar implementacao de reducer
   */
  [getExamTasks.type]: (state, action) => {
    const exam = state.exams!.find((exam) => exam.id === action.payload.id);
    if (exam && exam.tasks) {
      return exam.tasks;
    } else {
      return [];
    }
  },
  /** Retorna o índice da Task atual do Exam atual
   * TODO: revisar implementacao reducer
   */
  [getCurrentExamTask.type]: (state) => {
    const exam: any = state.exams!.find(
      (exam) => exam.id === state.currentExam
    );
    if (exam && exam.current) {
      return exam.current;
      // return exam.tasks.find(task => task.id === state.currentExam);
    }
  },

  /**
   *
   * @param state
   * @param action
   * @returns Atualiza o currentTask do Exam atual
   
  [setCurrentExamTask.type]: (state, action) => {
    return (state = {
      ...state,
      exams: state.exams.map((exam) => {
        if (exam.id === state.currentExam) {
          return {
            ...exam,
            current: action.payload.current,
          };
        }
        return exam;
      }),
    });
  },*/

  /**
   *
   * @param state
   * @param action
   * @returns Atualiza o currentTask do Exam atual
   */
  [setCurrentExamTask.type]: (state, action) => {
    if (state.exam !== undefined) {
      state.exam.current_task = action.payload;
    }
  },

  [showAssessment.type]: (state, action) => ({ ...state, ...action.payload }),

  [updateItemStatus.type]: (state, action) => {
    const idItem: number = action.payload.id;
    const status: StatusItem = action.payload.status;
    const indexCurrentTask: number | null | undefined =
      state.exam?.current_task;
    let examAux = JSON.parse(JSON.stringify(state.exam)) as IExam;
    let currenteTask = { ...examAux.tasks[indexCurrentTask as number] };

    let newItems = currenteTask?.task_data?.items.map((item) => {
      let newItem = { ...item };
      if (item.id === idItem) {
        if (newItem.status !== "answered") newItem.status = status;
      }
      return newItem;
    });

    let taskData = examAux.tasks[indexCurrentTask as number].task_data;
    if (taskData !== undefined && newItems) {
      taskData.items = newItems;
      examAux.tasks[indexCurrentTask as number].task_data = taskData;
      state.exam = examAux;
    }
  },
  
  [updateListOfItemsStatus.type]: (state, action) => {
    let items: number[] = action.payload;
    let examAux = JSON.parse(JSON.stringify(state.exam)) as IExam;
    
    examAux.tasks.forEach((task: any, indexCurrentTask: number) => {
      let newItems = task.task_data.items.map((item)=>{
        let newItem = {...item};
        if(items.includes(newItem.id)) {
          if(newItem.status !== "answered") newItem.status = "answered";
        }
        return newItem;
      });

      let taskData = examAux.tasks[indexCurrentTask as number].task_data;
      if (taskData !== undefined && newItems) {
        taskData.items = newItems;
        examAux.tasks[indexCurrentTask as number].task_data = taskData;
        state.exam = examAux;
      }
    })

  }
});
