import api from "services/api";

import { IListExamsByStudentReturnService } from "./types";
import { IStudentExam } from "store/reducers/Assessments/Actions/types";

export default class AssessmentsServices {
  public static async getListExamsByStudent(idUser: number) {
    return api.get<IListExamsByStudentReturnService>(
      "assessments/studentexams/",
      {
        params: {
          student: idUser,
        },
      }
    );
  }

  public static async getExamById(idExam: number) {
    return api.get(`assessments/exams/${idExam}`);
  }

  // StudentExams

  public static async getStudentExams(): Promise<IStudentExam[]> {
    return await api.get(`studentexams/`);
  }

  public static async postStudentExams(data: IStudentExam) {
    return await api.post(`studentexams/`, data);
  }

  public static async getStudentExamsById(id: number): Promise<IStudentExam> {
    return await api.get(`studentexams/${id}/`);
  }

  public static async updateStudentExams(id: number, data: IStudentExam) {
    return await api.put(`studentexams/${id}/`, data);
  }

  public static async patchStudentExams(id: number, started_at: string) {
    return await api.patch(`assessments/studentexams/${id}/`, { started_at });
  }

  public static async deleteStudentExams(id: number) {
    return await api.delete(`studentexams/${id}/`);
  }

  public static async findStudantExamData(id: number) {
    return await api.get(`assessments/studentexamsfull/${id}/`);
  }
}
