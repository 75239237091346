import { Redirect, Route, Switch } from "react-router-dom";

import Register from "../pages/Register";
import CheckAuth from "pages/Public/check-auth";

const PublicRoutes = () => {
  return (
    <Switch>
      <Route path="/" exact component={CheckAuth} />
      <Route path="/criar-conta" exact component={Register} />

      <Route path="*">
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};

export default PublicRoutes;
