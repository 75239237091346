import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { gettingStartedStudentExam } from "store/reducers/Exams/AsyncActions";

import {
  Box,
  Typography,
  Button
} from "@mui/material";

import EventOutlined from "@mui/icons-material/EventOutlined";
import ListAltOutlined from "@mui/icons-material/ListAltOutlined";
import AlarmOutlined from "@mui/icons-material/AlarmOutlined";
import WarningAmberOutlined from "@mui/icons-material/WarningAmberOutlined";

import Loading from "components/Loading";
import Header from "components/Header";
import { ListOfInstructions } from "./ListOfInstructions";

import { formatDate, isDateActived } from "helpers/formatDate";

import { updateListOfItemsStatus } from "store/reducers/Assessments/Actions";
import { ItemDetailsOfDescription } from "./ItemDetailsOfDescription";

import "./styles.scss";

export default function DescriptionEvaluation() {
  const { idAssessment } = useParams<{ idAssessment: string }>();
  const history = useHistory();

  const [detailsAssessments, setDetailsAssessments] = useState<any>({});
  const [qtdTotalQuestions, setQtdTotalQuestions] = useState(0);

  const dispatch = useDispatch<any>();

  const exams = useSelector((state: any) => state.exams);
  const { userData } = useSelector((state: any) => state.authentication);
  const respostas = useSelector((state: any) => state.answers);
  const examSelected = useSelector((state: any) => state.assessments);
  const { examAnswerEnabled } = useSelector(
    (state: any) => state.examStartController
  );

  const updateListStatusItems = () => {
    const items: number[] = respostas.answers.map((item) => item.item);

    if (items.length > 0) dispatch(updateListOfItemsStatus(items));

    history.push("/tarefa");
  }

  const startExam = (clicked?: boolean) => {
    if (clicked) {
      dispatch(gettingStartedStudentExam(
        detailsAssessments,
        updateListStatusItems
      ))

      return
    }

    if (examSelected.exam) updateListStatusItems();
  }

  useEffect(() => {
    startExam()

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (exams?.results?.length > 0) {
      const dataAssessments = exams?.results.filter(
        (exam: any) => exam.assessment === Number(idAssessment)
      );

      if (dataAssessments.length > 0) {
        setDetailsAssessments(dataAssessments[0]);

        let qtdTotalItems = 0;
        if (
          dataAssessments[0].exam_data &&
          dataAssessments[0].exam_data.tasks
        ) {
          for (const task of dataAssessments[0].exam_data.tasks) {
            qtdTotalItems += task.task_data.items.length;
          }
        }
        setQtdTotalQuestions(qtdTotalItems);
      } else {
        history.push("/");
      }
    } else {
      history.push("/");
    }

    /* Depois voltar essa função */
    // else {
    //     console.log('Passou');
    //     dispatch(asyncShowAssessment(5))
    // }
    // eslint-disable-next-line
  }, [exams.results, idAssessment]);

  const isAssessmentEnabledToAnswer = (
    startDate: Date,
    endDate: Date
  ): boolean => {
    let isEnabled: boolean = true;
    let atualDate: Date = new Date();
    let start: Date = new Date(startDate);
    let end: Date = new Date(endDate);

    if (atualDate < start || atualDate > end) {
      isEnabled = false;
    }
    return isEnabled;
  };

  return (
    <>
    <Box>
      <Header
        title="Nova Questão"
        onActionBtnArrowBack={() => history.push("/")}
        // subtitle={`Código ${detailsAssessments.code}`}
      />

      <Box>
        {/* <pre>
                {JSON.stringify(detailsAssessments.exam_data, null, 4)}
                {JSON.stringify(detailsAssessments.exam_data.tasks[0].task_data.items[0].id, null, 4)}
              </pre> */}
      </Box>
      <Box className="infos_header_of_evaluation">
        <Typography className="title_evaluation_unity">
          {detailsAssessments?.assessment_data?.title ?? "(Sem título)"}
        </Typography>

        <Typography className="description_evaluation_subject">
          {detailsAssessments?.assessment_data?.description}
        </Typography>
      </Box>

      <Box className="area_items_infos">
        <ItemDetailsOfDescription
          icon={<EventOutlined />}
          title={`Prazo: 
            ${detailsAssessments?.assessment_data?.ends_at &&
              formatDate({
                date: detailsAssessments?.assessment_data?.ends_at,
            })}`
          }
          description="Data para entregar a avaliação"
        />

        <ItemDetailsOfDescription
          icon={<ListAltOutlined />}
          title={`${qtdTotalQuestions} Questões`}
          description="Cada questão vale 0.5 pontos"
        />

        <ItemDetailsOfDescription
          icon={<AlarmOutlined />}
          title={`${detailsAssessments?.assessment_data?.exam_time} Horas`}
          description="A média de tempo para terminar a avaliação"
        />
      </Box>

      <Box className="list_instructions">
        <Typography className="title_list_instructions">
          <WarningAmberOutlined />
          Leia com atenção antes de começar
        </Typography>

        <Box>
          <ListOfInstructions />
        </Box>
      </Box>

      {(!detailsAssessments?.ended_at &&
        isDateActived(detailsAssessments?.assessment_data?.ends_at)) ||
      detailsAssessments.started_at ? (
        <Box className="action_continue">
          <Button
            variant="contained"
            size="large"
            fullWidth
            onClick={() => startExam(true)}
            disabled={
              !examAnswerEnabled ||
              (detailsAssessments?.ended_at && userData.type !== 'transcriber') ||
              !isAssessmentEnabledToAnswer(
                detailsAssessments?.assessment_data?.starts_at,
                detailsAssessments?.assessment_data?.ends_at
              )
                ? true
                : false
            }
            // style={{
            //   backgroundColor: "#5AB2E0",
            //   color: "white"
            // }}
          >
            {
              detailsAssessments?.ended_at
              ? "Avaliação Finalizada"
              : detailsAssessments.started_at
              ? "Continuar Avaliação"
              : "Iniciar Avaliação"}
          </Button>
        </Box>
      ) : (
        <></>
      )}
    </Box>
      
      <Loading  />
    </>
  );
}
