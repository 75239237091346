import { createReducer } from "@reduxjs/toolkit";

import { login, logout, setUserData } from "./Actions";

const INITIAL_VALUES = {
  user: {
    signed: false,
    token: "",
    idUser: 0,
  },
  userData: {},
};

export default createReducer(INITIAL_VALUES, {
  [login.type]: (state, action) => ({
    ...state,
    user: { ...action.payload.user },
  }),
  [logout.type]: () => ({ ...INITIAL_VALUES }),
  [setUserData.type]: (state, action) => ({
    ...state,
    userData: action.payload,
  }),
});
