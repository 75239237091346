import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";

import { MarkdownConvert } from "components/MarkdownConvert";

import { AssignmentSubHeaderProps } from "./types";

import "./styles.scss";

export default function AssignmentSubHeader({
  indice,
  title,
  content,
}: AssignmentSubHeaderProps) {
  return (
    <>
      <Box className="area_title_statement">
        {indice && <Avatar sx={{ width: 32, height: 32 }}>{indice}</Avatar>}
        {title && <Typography className="info_statement">{title}</Typography>}
      </Box>

      <Box className="area_statement">
        <MarkdownConvert textInMarkdown={content} />
      </Box>
    </>
  );
}
