import { AssessmentResourceType } from "store/reducers/Assessments";

export default function getConclusionPercentage(assessmentState: AssessmentResourceType) {
  if (
    assessmentState.exam?.current_task !== undefined &&
    assessmentState.exam?.current_task !== null
  ) {
    const tasks = assessmentState.exam?.tasks.map((task) => task.task_data);
    const items = tasks.flatMap((task) => task?.items);
    const sizeTasks = items?.length;
    const answeredTasksNumber = items?.filter(
      (item) => item?.status === "answered"
    ).length;
    if (sizeTasks && answeredTasksNumber) {
      return (answeredTasksNumber * 100) / sizeTasks;
    }
  }
  return 0;
}
