import { Box } from "@mui/material";
import React from "react";
import { generateClasses } from "./staticFunctions";
import "./styles.scss";

interface IProps {
  taskNumber: number;
  isSelectedTask?: boolean;
}
export const TaskContainer: React.FC<IProps> = ({
  children,
  taskNumber,
  isSelectedTask = false,
}) => {
  const getContainerClasses = () => {
    return generateClasses(
      ["task-container-items"],
      [
        {
          condition: isSelectedTask,
          conditionalClass: "selected-task",
        },
        {
          condition: !isSelectedTask,
          conditionalClass: "unselected-task",
        },
      ]
    );
  };
  return (
    <Box className={getContainerClasses()}>
      <Box className="task-number">
        <span>{taskNumber}</span>
      </Box>
      <Box className="task-itens">{children}</Box>
    </Box>
  );
};
