'use client'

import { useEffect, useState } from 'react';

export function Imagem({ id, src, caption='', width, align }){

 // const [imageClass, setImageClass] = useState('w-1/2')
  const [containerWClass, setContainerWClass] = useState('w-full')
  const [containerPClass, setContainerPClass] = useState('w-full')

  useEffect(()=>{

/*     if(imageWidth == 'metade'){
      setImageClass('w-1/2');
    }
    if(imageWidth == 'largura'){
      setImageClass('w-full');
    } */

    if(align == 'esquerda'){
      setContainerPClass('float-left');
    }
    if(align == 'direita'){
      setContainerPClass('float-right');
    }
    if(align == 'centro'){
      setContainerPClass('');
    }

    if(width == 'largura'){
      setContainerWClass('w-full');
    }
    if(width == 'metade'){
      setContainerWClass('w-1/2');
    }
    if(width == 'terco'){
      setContainerWClass('w-1/3');
    }

  },[align,width])

  return (
    <>
    <span className={`flex flex-col items-center ${containerWClass} ${containerPClass}`}>
      <img src={src} alt="misc" />
    
      {caption && 
        <span className="text-sm text-center align-top text-[#aaa]">
          {caption}
        </span>
      }
    </span>
    </>
  )
}

{/* <span style={{position:'relative',height:300,display: 'block'}}>
      <img src={src} alt="misc" style={{objectFit: 'contain'}} />
    </span>
    {caption && 
        <span className="text-sm text-center align-top text-[#aaa]">
          {caption}
        </span>
      } */}