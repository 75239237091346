import React from 'react';

// Importando as rotas 
import PublicRoutes from "./PublicRoutes";
import PrivateRoutes from './PrivateRoutes';

import { useSelector } from 'react-redux';

const Routes : React.FC = () => {
    const { user } = useSelector((state: any) => state.authentication);


    return user.signed ? <PrivateRoutes /> : <PublicRoutes />
}
export default Routes; 
