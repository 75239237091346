import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    typography: {
        fontFamily: 'Poppins, Roboto',
    },
    components: {
        MuiTab: {
            styleOverrides: {
                textColorPrimary: 'red',
                
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                    transition: 'background-color 0.2s',
                    fontFamily: 'Poppins',
                    ':hover': {
                        boxShadow: 'none',
                    }
                },
                containedPrimary: {
                    fontFamily: 'Poppins',
                    color: "var(--neutral-1)",
                    backgroundColor: "var(--primary-regular)",
                    transition: 'all 0.2s linear',
                    '&:hover': {
                        backgroundColor: "var(--primary-regular)",
                        filter: 'brightness(0.9)',
                        color: "var(--neutral-1)",
                    }
                },
                containedSecondary: {
                    fontFamily: 'Poppins',
                    color: "var(--primary-regular)",
                    backgroundColor: "var(--primary-lighter)",
                    '&:hover': {
                        color: "var(--primary-regular)",
                        backgroundColor: "var(--primary-lighter)",
                        filter: 'brightness(0.9)',
                    }
                },
                containedWarning: {
                    fontFamily: 'Poppins',
                    color: "var(--neutral-1)",
                    backgroundColor: "var(--wheat-regular)",
                    transition: 'all 0.2s linear',
                    '&:hover': {
                        backgroundColor: "var(--wheat-regular)",
                        filter: 'brightness(0.9)',
                        color: "var(--neutral-1)",
                    }
                },
            }
        }
    }
});
