import { StatusItem } from "../../types";

const _statusColors: Record<StatusItem, string> = {
  current: "var(--primary-light)",
  answered: "var(--primary-regular)",
  unanswered: "var(--neutral-3)",
};

export function getColorItem(status: StatusItem): string {
  return _statusColors[status];
}
