import { Dispatch, SetStateAction } from "react";

import { useDispatch } from 'react-redux'

import { toast } from "react-toastify";

import {
  Button,
  Dialog,
  DialogContent,
  Typography,
} from "@mui/material";

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

import { setUserApplication } from "store/reducers/Application/actions";

import { IRegisterProps } from "store/reducers/Authentication/AsyncActions/types";

import './styles.scss'

interface IDialogConfirmSelectStudent {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>
  selectedStudent?: IRegisterProps
}

export function DialogConfirmSelectStudent(props: IDialogConfirmSelectStudent) {
  const dispatch = useDispatch()

  const handleConfirmSelection = () => {
    if (!props.selectedStudent) {
      toast.error('Necessário informar o aluno')
      
      return
    };
    
    dispatch(setUserApplication(props.selectedStudent))
  }

  return (
    <Dialog
      open={props.open}
      maxWidth="sm"
      fullWidth
    >
      <DialogContent dividers className="content_confirm_select_student">
        <Typography className="text_confirm_select">A busca pelo identificador informado resultou na identificação:</Typography>
        <Typography className="name_student_selected">{props.selectedStudent?.first_name} {props.selectedStudent?.last_name}</Typography>

        <Typography className="text_confirm_select">Confirma a seleção?</Typography>

        <div className="confirm_or_cancel_selection">
          <Button
            variant="contained"
            color="primary"
            startIcon={<CheckIcon />}
            onClick={handleConfirmSelection}
          >
            Sim
          </Button>

          <Button
            variant="contained"
            color="secondary"
            startIcon={<CloseIcon />}
            onClick={() => props.setOpen(false)}
          >
            Não
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}