"use client";

import React, { useEffect, useContext, useState } from "react";
import ReactMarkdown from "react-markdown"; // Componente pra renderizar markdown e os componentes personalizados
import remarkGfm from "remark-gfm"; // Plugin pra suporte a Github Flavored Markdown (GFM)
import remarkMath from "remark-math"; // Plugin pra suporte a expresões matemáticas
import rehypeKatex from "rehype-katex"; // Plugin pra renderizar as expressões matemáticas utilizando a biblioteca KaTeX
import remarkDirective from "remark-directive"; // Plugin pra suporte a diretivas personalizadas
import remarkDirectiveRehype from "remark-directive-rehype"; // Plugin pra transformar diretivas em elementos rehype

import Imagem from "../components/imagem"; // O componente personalizado usado pra renderizar as imagens
import Exempluuuuu from "../components/exemplo";

// Importando os estilos do KaTeX
// import "katex/dist/katex.min.css"; // `rehype-katex` does not import the CSS for you

import {RenderProvider, RenderContext} from './RenderContext'

function InternalMarkdownRender({ markdownCode, components }) {

  const [num, setNum] = useState(0);
  const { valoresObj, setValoresObj } =
    useContext(RenderContext);

  useEffect(()=>{
    setValoresObj({});
    setNum((prev)=>prev+1);
  },[markdownCode]);

  return (
        <ReactMarkdown
          key={num}
          remarkPlugins={[
            remarkGfm,
            remarkMath,
            remarkDirective,
            remarkDirectiveRehype,
          ]}
          rehypePlugins={[rehypeKatex]}
          components={
            {
              ...components,
              exemp: Exempluuuuu,
              imagem: Imagem,
            }
          }
        >
          {markdownCode}
        </ReactMarkdown>
  );
}


export function MarkdownRender({ markdownCode, components }) {

  return (
    <div className="preview">
      <RenderProvider>
        <InternalMarkdownRender
          components={
            {
              ...components,
              exemp: Exempluuuuu,
              imagem: Imagem,
            }
          }
          markdownCode={markdownCode}
        />
      </RenderProvider>
    </div>
  );
}
