import { useHistory } from "react-router-dom";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

import KeyboardArrowRightOutlined from "@mui/icons-material/KeyboardArrowRightOutlined";
import AssistantPhotoOutlined from "@mui/icons-material/AssistantPhotoOutlined";
import EventOutlined from "@mui/icons-material/EventOutlined";

import { ItemEvidencesProps } from "./types";

import "./styles.scss";
import { fetchExamData } from "store/reducers/Exams/AsyncActions";
import { useDispatch, useSelector } from "react-redux";
import { open_dialog, close_dialog } from "store/reducers/Loading/Actions";

export default function ItemEvidencesSecondary({
  type,
  idAssessment,
  idExam,
  title,
  progress,
  timeStart,
  timeTerm,
  timeFinished,
  examName,
  code,
}: ItemEvidencesProps) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { results } = useSelector((state: any) => state).exams;

  const fecthExamDataTasks = async (idAssessment: number) => {
    dispatch(
      open_dialog({
        open: true,
        fullWidth: true,
        maxWidth: "sm",
        title: "Carregando Avaliações...",
      })
    );

    const assessment: any = results.filter((r) => {
      return r.assessment === idAssessment && r.exam === idExam;
    });

    //TODO: Adiciona aqui dispatch para verificar carregamento
    await dispatch(fetchExamData(idAssessment, idExam, assessment[0].id));

    dispatch(close_dialog);

    history.push(`/informacoes-avaliacao/${idAssessment}`);
  };

  return (
    <>
      <Box className="item_module grid">
        <Box
          className="item_body"
          onClick={() => fecthExamDataTasks(idAssessment)}
        >
          <Box className="area_img_module">
            <img
              // src="/assets/evidences.svg"
              // src="/assets/png/logo.png"
              src="/assets/cenpe.svg"
              alt="Imagem da Prova"
              className="img_module"
            />
          </Box>

          <Box className="details_module">
            <Box className="details_header">
              <Typography className="details_module_title">
                {examName}
              </Typography>

              <Typography className="details_module_detail">{title}</Typography>

              <Box className="dates_info">
                <Typography className="details_module_classes">
                   Cod: {code}
                </Typography>
                {type === "actived" ? (
                  <>
                    <Typography className="details_module_classes">
                      <AssistantPhotoOutlined /> Início: {timeStart}
                    </Typography>

                    <Typography className="details_module_classes">
                      <EventOutlined /> Prazo: {timeTerm}
                    </Typography>
                  </>
                ) : type === "finished" ? (
                  <Typography className="details_module_classes">
                    <AssistantPhotoOutlined /> Concluída: {timeFinished}
                  </Typography>
                ) : (
                  <Typography className="details_module_classes">
                    <EventOutlined /> Expirada: {timeTerm}
                  </Typography>
                )}
              </Box>
              {
                //<Box style={{ position: 'relative', width: '100%' }}>
                //    <span className="progress">
                //        <span className="progress_finished" style={{ width: `${progress}%` }}/>
                //   </span>
                //</Box>
              }
            </Box>

            <Box className="area_starts">
              {/* {type !== "actived" && (
							<Typography className="note">
								{type === "finished" ? "7.0" : "-"}
							</Typography>
						)} */}
              <IconButton className="btn_play_and_note">
                <KeyboardArrowRightOutlined htmlColor="#365BDC" />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
