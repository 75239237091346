interface IConditialClass {
  condition: boolean;
  conditionalClass: string;
}

function _validateConditionalClasses(
  staticClasses: string[],
  conditionalClasses: IConditialClass[] | IConditialClass
): string[] {
  if (conditionalClasses) {
    if (Array.isArray(conditionalClasses)) {
      conditionalClasses.forEach(({ condition, conditionalClass }) => {
        if (condition) {
          staticClasses.push(conditionalClass);
        }
      });
    } else {
      if (conditionalClasses.condition) {
        staticClasses.push(conditionalClasses.conditionalClass);
      }
    }
  }
  return staticClasses;
}

export function generateClasses(
  staticClasses: string[],
  conditionalClasses?: IConditialClass[] | IConditialClass
): string {
  if (conditionalClasses) {
    return _validateConditionalClasses(staticClasses, conditionalClasses)
      .join(" ")
      .toString();
  }
  else {
    return staticClasses.join(" ").toString();
  }
}
