import ReactMarkdown from "react-markdown";

import remarkGfm from "remark-gfm";
import remarkMath from "remark-math";

import rehypeRaw from "rehype-raw";
import rehypeKatex from "rehype-katex";

import { MarkdownRender } from "assets/cempe-lib/lib/editor";
import { Imagem } from "assets/cempe-lib/lib/editor/components/imagem";
import { Audio } from "assets/cempe-lib/lib/editor/components/audio";
import { Dica } from "assets/cempe-lib/lib/editor/components/dica";
import { Explanation } from "assets/cempe-lib/lib/editor/components/explanation";
import { Observacao } from "assets/cempe-lib/lib/editor/components/observacao";
import { Video } from "assets/cempe-lib/lib/editor/components/player";
import { Equacao } from "assets/cempe-lib/lib/editor/components/equacao";

import "katex/dist/katex.min.css";
import "assets/cempe-lib/style.css"

interface IMarkdownConvertProps {
  textInMarkdown: string;
}

export function MarkdownConvert({ textInMarkdown }: IMarkdownConvertProps) {
  return (
    <div>
      {/* <ReactMarkdown
        children={textInMarkdown}
        remarkPlugins={[remarkGfm, remarkMath]}
        rehypePlugins={[rehypeRaw, rehypeKatex]}
      /> */}
      <MarkdownRender
      markdownCode={textInMarkdown}
      components={{
        dica:Dica,
        obs:Observacao,
        ex:Explanation,
        equacao:Equacao,
        img:Imagem,
        vi:Video,
        au:Audio,
      }}
      />
    </div>
  );
}
