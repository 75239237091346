"use client";

import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";

import { MarkdownRender } from "../markdownRender";

export function Explanation({ text }) {
  const [listExplanation, setListExplanation] = useState("");
  var temp;

  useEffect(() => {
    if (text) {
      temp = text.split("&");
      setListExplanation(
        temp.map((mapText, idx) => (
          <div key={idx} className={"flex flex-row gap-x-2"}>
            <FontAwesomeIcon
              icon={faCircleCheck}
              style={{ color: "#3F26D9" }}
              className="h-4 py-1"
            />{" "}
            <li>
              <MarkdownRender fit markdownCode={mapText} />
            </li>
          </div>
        ))
      );
    }
  }, [text]);

  return (
    <>
      <div className={"comp align-center bg-slate-100 rounded-md p-2 my-1"}>
        <ul
          className={`re-render flex flex-col gap-y-4  p-3`}
          style={{ listStyle: "none" }}
        >
          {listExplanation}
        </ul>
      </div>
    </>
  );
}
