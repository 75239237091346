import api from "services/api";
import {
  // IAssessmentExam,
  IAssessment,
  IExam,
  // IStudentAnswer
} from "../Actions/types";

// AssessmentExams
// export const getAssessmentExams = async(): Promise<IAssessmentExam[]> => {
//   return await api.get(`assesmentexams/`);
// }

// export const postAssessmentExams = async(data: IAssessmentExam) => {
//   return await api.post(`assesmentexams/`, data);
// }

// export const getAssessmentExamsById = async(id: number): Promise<IAssessmentExam> => {
//   return await api.get(`assesmentexams/${id}/`);
// }

// export const updateAssessmentExams = async(id: number, data: IAssessmentExam) => {
//   return await api.put(`assesmentexams/${id}/`, data);
// }

// export const patchAssessmentExams = async(id: number, data: IAssessmentExam) => {
//   return await api.patch(`assesmentexams/${id}/`, data);
// }

// export const deleteAssessmentExams = async(id: number) => {
//   return await api.delete(`assesmentexams/${id}/`);
// }

// Assessments

export const getAssessments = async(): Promise<IAssessment[]> => {
  return await api.get(`assesments/`);
}

export const postAssessment = async(data: IAssessment) => {
  return await api.post(`assesments/`, data);
}

export const getAssessmentById = async(id: number) => {
  return await api.get<IAssessment>(`assessments/assessments/${id}/`);
}

export const updateAssessment = async(id: number, data: IAssessment) => {
  return await api.put(`assesments/${id}/`, data);
}

export const patchAssessment = async(id: number, data: IAssessment) => {
  return await api.patch(`assesments/${id}/`, data);
}

export const deleteAssessment = async(id: number) => {
  return await api.delete(`assesments/${id}/`);
}


// Exams

export const getExams = async(): Promise<IExam[]> => {
  return await api.get(`exams/`);
}

export const postExams = async(data: IExam) => {
  return await api.post(`exams/`, data);
}

export const getExamsById = async(id: number|string): Promise<IExam> => {
  return await api.get(`exams/${id}/`);
}

export const updateExams = async(id: number, data: IExam) => {
  return await api.put(`exams/${id}/`, data);
}

export const patchExams = async(id: number, data: IExam) => {
  return await api.patch(`exams/${id}/`, data);
}

export const deleteExams = async(id: number) => {
  return await api.delete(`exams/${id}/`);
}


// StudentAnswers

// export const getStudentAnswers = async(): Promise<IStudentAnswer[]> => {
//   return await api.get(`studentexams/`);
// }

// export const postStudentAnswers = async(data: IStudentAnswer) => {
//   return await api.post(`studenteanswers/`, data);
// }

// export const getStudentAnswersById = async(id: number): Promise<IStudentAnswer> => {
//   return await api.get(`studenteanswers/${id}/`);
// }

// export const updateStudentAnswersExams = async(id: number, data: IStudentAnswer) => {
//   return await api.put(`studenteanswers/${id}/`, data);
// }

// export const patchStudentAnswersExams = async(id: number, data: IStudentAnswer) => {
//   return await api.patch(`studenteanswers/${id}/`, data);
// }

// export const deleteStudentAnswersExams = async(id: number) => {
//   return await api.delete(`studenteanswers/${id}/`);
// }

// TaskExams

export const getTaskExams = async(): Promise<IExam[]> => {
  return await api.get(`taskexams/`);
}

export const postTaskExams = async(data: IExam) => {
  return await api.post(`taskexamsxams/`, data);
}

export const getTaskExamsById = async(id: number): Promise<IExam> => {
  return await api.get(`taskexams/${id}/`);
}

export const updateTaskExams = async(id: number, data: IExam) => {
  return await api.put(`taskexams/${id}/`, data);
}

export const patchTaskExams = async(id: number, data: IExam) => {
  return await api.patch(`taskexams/${id}/`, data);
}

export const deleteTaskExams = async(id: number) => {
  return await api.delete(`taskexams/${id}/`);
}

