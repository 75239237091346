import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";

import { useSelector } from 'react-redux';

import { IOpenDialogLoading } from 'store/reducers/Loading/Actions/types';

import './styles.scss';

export default function Loading() {
    const loading: IOpenDialogLoading = useSelector((state: any) => state.loading)

    return (
        <Dialog
            open={loading.open}
            fullWidth={loading.fullWidth}
            fullScreen={loading.fullScreen}
            maxWidth={loading.maxWidth}
        >
            <DialogContent>
                <Box className="area_loading">
                    <Typography className="title_loading">
                        {loading.title}
                    </Typography>

                    <CircularProgress className="progress_loading" />
                </Box>
            </DialogContent>
        </Dialog>
    )
}