import Box from "@mui/material/Box";

import { StatusItem } from "./types";
import "./styles.scss";
import { TaskContainer } from "./components/TaskContainer";
import { ItemStatus } from "./components/ItemStatus";
import { useSelector } from "react-redux";
import { IExam, ITask } from "store/reducers/Assessments/Actions/types";

type TItemTask = {
  id: number | undefined;
  status: StatusItem;
};

type TTask = {
  id_task: number | undefined;
  actual_task?: boolean;
  itens: TItemTask[];
};

export default function AssignmentIndicatorTasks() {
  const currentExam: IExam | undefined = useSelector(
    (state: any) => state.assessments
  ).exam;
  const tasks: TTask[] = buildProgressData(currentExam);
  return (
    <Box className="content_indicator">
      {tasks.map((task, i) => (
        <TaskContainer
          taskNumber={i + 1}
          isSelectedTask={task?.actual_task}
          key={Math.random()}
        >
          {task.itens.map((item, i) => (
            <ItemStatus status={item.status} key={Math.random()} />
          ))}
        </TaskContainer>
      ))}
    </Box>
  );
}

function buildItemTask(task: ITask | undefined): TItemTask[] {
  let itemDataList: TItemTask[] = [];
  if (task !== undefined) {
    for (let item of task.items) {
      let itemData: TItemTask = {
        id: item.id as number,
        status:
          item.status !== undefined
            ? (item.status as StatusItem)
            : "unanswered",
      };
      itemDataList.push(itemData);
    }
  }
  return itemDataList;
}

function buildProgressData(exam: IExam | undefined): TTask[] {
  let tasksData: TTask[] = [];
  if (exam !== undefined) {
    const currentTaskId: number = exam.tasks[exam.current_task as number].task;
    for (let examTask of exam.tasks) {
      let task: TTask = {
        id_task: examTask.task,
        actual_task: currentTaskId === examTask.task,
        itens: buildItemTask(examTask.task_data),
      };
      tasksData.push(task);
    }
  }
  return tasksData;
}
