import { createReducer } from "@reduxjs/toolkit";
import { IAssessmentExam } from "../Assessments/Actions/types";

import {
  list_by_user,
  set_exam_data,
  update_item_results_studentexams,
} from "./Actions";

export default createReducer<any>(
  {},
  {
    [list_by_user.type]: (state, action) => ({ ...state, ...action.payload }),
    [update_item_results_studentexams.type]: (state, action) => ({
      ...state,
      results: [...state.results, ...action.payload],
    }),

    [set_exam_data.type]: (state, action) => {
      let i: number = -1;
      let data: IAssessmentExam = { ...action.payload };
      for (let assessment of state.results) {
        i += 1;
        if (assessment.assessment === (data.assessment as number)) break;
      }

      let assessmentChange: any[] = JSON.parse(JSON.stringify(state.results));
      assessmentChange[i].exam_data = { ...data.exam_data };

      state.results = [...assessmentChange];
    },
  }
);
