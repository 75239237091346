'use client'

import { useEffect, useState } from 'react';
import ReactMarkdown from "react-markdown"; // Componente pra renderizar markdown e os componentes personalizados
import remarkGfm from "remark-gfm"; // Plugin pra suporte a Github Flavored Markdown (GFM)
import remarkMath from "remark-math"; // Plugin pra suporte a expresões matemáticas
import rehypeKatex from "rehype-katex"; // Plugin pra renderizar as expressões matemáticas utilizando a biblioteca KaTeX
import remarkDirective from "remark-directive"; // Plugin pra suporte a diretivas personalizadas
import remarkDirectiveRehype from "remark-directive-rehype"; // Plugin pra transformar diretivas em elementos rehype

import {MarkComponent} from '../editor/markComponent';

/*
exemplo de uso:
::exemp{id="22"  prop1=2aa propA=xuxu} 
saida:
TESTADOR 2aa 4
*/

/*
exemplo da notação
::obs{a=22 b=55}[hfdkjhgjkhfdkjgfg]

:::obs{ppp=a}

sjgkldsjg
gdsjkdsjg
:::
*/


export default function Exemplaaaaaa({ propA, prop1, propB, prop2, equs="", children }){

  const [stad, setStad] = useState(0)

  useEffect(()=>{
    console.log(propA);
    if(propA){
      setStad(propA.length);
    }
  },[propA])

  return (
    <>
      <span className={``}>
        TESTADOR {prop1} {prop2} {stad}
      </span>
      {children}
      {
        equs.split(';').map((eq,i) => {
          return (
            <MarkComponent key={i}>
              {eq}
            </MarkComponent>
          )
        })
      }
    </>
  )
}