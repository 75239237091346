import React from "react";

import { useAuth } from "@cenpe/auth";

import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { Box, Container, ThemeProvider } from "@mui/material";

import Loading from "components/Loading";
import Appbar from "./components/Appbar";

import store from "store";
import { theme } from "theme";
import Routes from "./routes";

import "react-toastify/dist/ReactToastify.css";
import './assets/globalStyles.css'

export default function App() {
  const { check } = useAuth.getState();

  function onRedirect() {
    sessionStorage.clear();
  }
  React.useEffect(() => {
    setInterval(() => {
      check({ withRedirectSSO: true, onRedirect });
    }, 1000);
  }, []);
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <Appbar />
          <Container maxWidth="md" disableGutters>
            <Box
              flexDirection="column"
              alignContent="center"
              justifyContent="space-between"
              padding={0}
            >
              <Loading />
              <Routes />
            </Box>
          </Container>

          <ToastContainer
            position="top-right"
            theme="colored"
            autoClose={2000}
          />
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  );
}
