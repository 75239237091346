import katex from "katex";
import "katex/dist/katex.min.css";
import { useEffect, useRef } from "react";

import { MarkdownRender } from "../markdownRender";

export function Observacao({ text = "" }) {
  const obsRef = useRef();

  return (
    <div
      ref={obsRef}
      className="re-render comp text-ava-neutros-dark text-base font-poppins text-justify mt-2 mb-2"
      style={{
        padding: "25px 23px 21px 23px",
        background: "#E9EDF6",
        borderRadius: "8px",
      }}
    >
      <MarkdownRender fit markdownCode={text} />
    </div>
  );
}
