interface IFormatDateProps {
  date: string;
  withHour?: boolean;
  dateSeparator?: string;
}

export function formatDate({
  date,
  withHour = false,
  dateSeparator = "/",
}: IFormatDateProps): string {
  const dateToFormat: Date = new Date(date);
  const day = dateToFormat.getDate().toString().padStart(2, "0");
  const month = (dateToFormat.getMonth() + 1).toString().padStart(2, "0");
  const year = dateToFormat.getFullYear();

  let response = day + dateSeparator + month + dateSeparator + year;
  if (withHour) {
    const hours = dateToFormat.getHours().toString().padStart(2, "0");
    const minutes = dateToFormat.getMinutes().toString().padStart(2, "0");
    const hourDivider = hours + ":" + minutes;
    response += ` - ${hourDivider}h`;
  }

  return response;
}

export const isDateActived = (date: string) => {
  const dateAssessment = new Date(date);
  const dateNow = new Date();

  if (dateNow <= dateAssessment) {
    return true;
  }

  if (dateNow > dateAssessment) {
    return false;
  }
};
