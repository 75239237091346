import { createReducer } from "@reduxjs/toolkit";
import { 
  getStudentAnswer,
  getStudentAnswerById,
  getStudentAnswerByItem,
  setStudentAnswer,
  addStudentAnswer,
  patchStudentAnswer,
  deleteStudentAnswer,
  clearStudentAnswer
} from "./Actions/index";
import { IStudentAnswer } from "./Actions/types";

type StatePropsType = {
  answers: IStudentAnswer[]|[];
}

const INITIAL_VALUES: StatePropsType = {
  answers: []
}

export default createReducer(INITIAL_VALUES,{
  // Retorna o array com as ansers do aluno
  [getStudentAnswer.type]: (state) => state.answers,
  // Retorna a resposta com o ID especificado
  [getStudentAnswerById.type]: (state, action) => state.answers.find((answer) => answer.id === action.payload.id),
  [getStudentAnswerByItem.type]: (state, action) => (
    state.answers.find((answer) => 
      answer.item === action.payload
    )
  ),
  // Adiciona uma nova answer para o estado
  [addStudentAnswer.type]: (state, action) => ({
    ...state, 
    answers: [...state.answers, action.payload]
  }),
  // Atualiza os dados do answer que possuir o id repassado
  [setStudentAnswer.type]: (state, action) => ({
    ...state, 
    answers: state.answers.map((answ) => {
      return answ.item !== action.payload.item ? answ : action.payload
    })
  }),
  // Atualiza os dados do answer que possuir o id repassado
  [patchStudentAnswer.type]: (state, action) => {
    state = {
    ...state, 
    answers: state.answers.map((answ) => {
      return answ.id !== action.payload.id ? answ : action.payload
    })
  }},
  // Recebe um ID e em seguida remove do state a answer que possuir aquele ID
  [deleteStudentAnswer.type]: (state, action) => ({
    ...state, 
    answers: state.answers.filter((answ) => {
      return answ.item !== action.payload
    })
  }),
  // Limpa todas as respostas armazenadas
  [clearStudentAnswer.type]: (state) => {
    state= {
      answers: INITIAL_VALUES.answers
  }},
})
