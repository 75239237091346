import { createReducer } from "@reduxjs/toolkit";
import {
  disable_button_answer_exam,
  enable_button_answer_exam,
} from "./Actions";

const INITIAL_STATE = {
  examAnswerEnabled: false,
};

export default createReducer<any>(INITIAL_STATE, {
  [enable_button_answer_exam.type]: (state) => ({
    ...INITIAL_STATE,
    examAnswerEnabled: true,
  }),
  [disable_button_answer_exam.type]: (state) => ({
    ...INITIAL_STATE,
    examAnswerEnabled: false,
  }),
});
