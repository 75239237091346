import { createReducer } from "@reduxjs/toolkit";
import { IRegisterProps } from "../Authentication/AsyncActions/types";
import { resetUserApplication, setUserApplication } from "./actions";

interface IApplicationState {
  userApplication?: IRegisterProps;
}

const INITIAL_STATE: IApplicationState = {
  userApplication: undefined,
};

const trancriberStore = createReducer(INITIAL_STATE, {
  [setUserApplication.type]: (state, action) => {
    sessionStorage.setItem("@App:UApp", window.btoa(JSON.stringify(action.payload)));

    return (
      {
        ...state,
        userApplication: action.payload,
      }
    )
  },
  [resetUserApplication.type]: () => {
    sessionStorage.removeItem('@App:UApp')
    
    return INITIAL_STATE
  },
});

export default trancriberStore;
