import React from "react";
import { Route, Switch } from "react-router-dom";

import { EvidencesRouter } from "pages/ListEvidences/EvidencesRouter";
import DescriptionEvaluation from "pages/DescriptionEvaluation";
import Assignment from "pages/Assignment";
import NotFound from "pages/404";

const PrivateRoutes: React.FC = () => {
  return (
    <Switch>
      <Route path="/" exact component={EvidencesRouter} />
      <Route
        path="/informacoes-avaliacao/:idAssessment"
        exact
        component={DescriptionEvaluation}
      />
      <Route path="/tarefa" component={Assignment} />
      <Route path="*" component={NotFound} />
    </Switch>
  );
};

export default PrivateRoutes;
