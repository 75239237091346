import { Dispatch, SetStateAction, useEffect, useState } from 'react'

import {
    Dialog,
    DialogTitle,
    DialogContent,
    InputAdornment,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    TextField
} from '@mui/material'

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import SearchIcon from '@mui/icons-material/Search'

export interface IListForFilter {
    id: number;
    name: string;
}

interface IDialogSelect {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    typeSelect: 'school' | 'class' | 'student';
    listItems: IListForFilter[]
    onSelectItem: (typeSelect: 'school' | 'class' | 'student', item: IListForFilter) => void;
}

export function DialogSelect(props: IDialogSelect) {
    const [itemSelected, setItemSelected] = useState(0)

    const [listItems, setListItems] = useState<IListForFilter[]>([])

    const onFilter = (text: string) => {
        const listFiltered =  props.listItems.filter(item => item.name.toLowerCase().includes(text.toLowerCase()))

        setListItems(listFiltered)
    }

    const onSelectItem = (item: IListForFilter) => {
        setItemSelected(item.id)
        props.onSelectItem(props.typeSelect, item)
        props.setOpen(false)
    }

    useEffect(() => {
        if (props.listItems) {
            setListItems(props.listItems)
        }
    }, [props.listItems])

    return (
        <Dialog
            open={props.open}
            onClose={() => props.setOpen(false)}
        >
            <DialogTitle>
                <TextField
                    variant="outlined"
                    label="Pesquisar:"
                    placeholder="Digite o nome para filtrar..."
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    fullWidth
                    onChange={(e) => onFilter(e.target.value)}
                />
            </DialogTitle>

            <DialogContent>
                <List component="nav">
                    {
                        listItems.map(item => (
                            <ListItemButton
                                key={`item-${item.id}`}
                                selected={itemSelected === item.id}
                                onClick={() => onSelectItem(item)}
                            >
                                <ListItemIcon>
                                    <KeyboardArrowRightIcon />
                                </ListItemIcon>

                                <ListItemText primary={item.name} />
                            </ListItemButton>
                        ))
                    }
                </List>
            </DialogContent>
        </Dialog>
    )
}