import ExamsServices from "./exams.services";

import { updateAssessmentState } from "store/reducers/Assessments/Actions";
import { getAssessmentById } from "store/reducers/Assessments/AsyncActions/assessments.service";
// import TasksServices from "store/reducers/Tasks/AsyncActions/tasks.services";
// import { getStudentAnswersById } from "store/reducers/StudentAnsers/AsyncActions/studentsanswers.service";

import { open_dialog, close_dialog } from "store/reducers/Loading/Actions";
import { list_by_user, set_exam_data } from "../Actions";

import {
  IAssessmentExam,
  // IItem,
  // IExam,
  IStudentExam,
  // ITaskExam,
} from "store/reducers/Assessments/Actions/types";
// import { getStudentAnswers } from "store/reducers/StudentAnsers/AsyncActions";
import { addStudentAnswer } from "store/reducers/StudentAnsers/Actions";
import {
  disable_button_answer_exam,
  enable_button_answer_exam,
} from "store/reducers/StartExamController/Actions";

function openLoading({ dispatch, title = "" }: { dispatch: any; title: string; }) {
  dispatch(
    open_dialog({
      open: true,
      title,
      fullWidth: true,
      maxWidth: "sm",
    })
  )
}

export const listExamsByUser = (idUser: number) => {
  return (dispatch: any) => {
    openLoading({
      dispatch,
      title: "Carregando Avaliações...",
    })

    ExamsServices.getListExamsByStudent(idUser).then(async ({ data }) => {
      let dataListExamsStudent: any = { ...data };
      let getAllResults: any = [];

      for (const item of data.results) {
        const iExam: any = item;

        let dataAssessments = {};
        let dataExam: any = {};

        await getAssessmentById(iExam.assessment).then((response) => {
          dataAssessments = { assessment_data: response.data };
        });

        getAllResults.push({
          ...iExam,
          ...dataAssessments,
          exam_data: { ...dataExam },
        });
      }

      dispatch(
        list_by_user({ ...dataListExamsStudent, results: getAllResults })
      );
      dispatch(close_dialog());
    });
  };
};

/**
 * 
 * @param idAssessment 
 * @param idExam 
 * @param studantExamId 
 * @returns 
 * 
 * Busca dados das questões e as exibe para o estudante na tela de detalhes. 
 * Exibe o loading enquanto está nesta tela
 * Habilita o botão de continuar ou iniciar avaliação
 */
export const fetchExamData = (
  idAssessment: number,
  idExam: number,
  studantExamId: number
) => {
  return (dispatch: any) => {
    let dataExam: any = {};
    dispatch(disable_button_answer_exam());
    ExamsServices.findStudantExamData(studantExamId).then(
      async (response: any) => {
        /**
         * Executa o dispatch e muda o estado do loader para true, o que faz com que seja exibido
         * o loading enquanto os detalhes das avaliações são buscados.
         */
        openLoading({
          dispatch,
          title: "Carregando suas informações, por favor, aguarde.",
        })

        dataExam = { ...response.data };

        let returnArraytasks: any = [];
        let qtdItemsAnswered = 0;
        let qtdItems = 0;
        let taskAnswers: any = [];

        for (const taskExam of dataExam.exam.tasks) {
          let tasksExamCP = { ...taskExam };
          tasksExamCP = { ...tasksExamCP, task_data: { ...tasksExamCP.task } };
          qtdItems += tasksExamCP.task_data!.items.length;

          for (let item of tasksExamCP.task_data!.items) {
            let answers = dataExam.answers.filter((answer) => {
              return answer.item === item.id;
            });

            if (answers !== undefined && answers.length > 0) {
              dispatch(addStudentAnswer(answers[0]));
              taskAnswers = [...taskAnswers, answers];
              qtdItemsAnswered += 1;
            }
          }
          returnArraytasks = [...returnArraytasks, tasksExamCP];
        }

        dataExam = {
          ...dataExam,
          taskAnswers,
          percentProgress: ((qtdItemsAnswered * 100) / qtdItems).toFixed(2),
          tasks: returnArraytasks,
        };
        const assessmentExam: IAssessmentExam = {
          assessment: idAssessment,
          exam_data: dataExam,
        };

        dispatch(set_exam_data(assessmentExam));
        dispatch(enable_button_answer_exam());
        /**
         * Adicionei este timeout pois o diálogo abria e fechava muito rápido, o que se 
         * tornada incomodo após algumas iterações, não era possível ler o que estava na tela
         */
        setTimeout(() => {
          dispatch(close_dialog());
        }, 2000);
        // dispatch(close_dialog());
      }
    );
  };
};

export const gettingStartedStudentExam = (
  studentExamSelected: IStudentExam,
  handleUpdateListStatusItems: () => void = () => {},
) => {
  return async (dispatch: any) => {
    openLoading({
      dispatch,
      title: "Iniciando prova...",
    })

    const STARTED_AT = new Date().toISOString();

    if (studentExamSelected.started_at) {
      await dispatch(
        updateAssessmentState({
          student_exam: studentExamSelected.id!,
          exam: {
            ...studentExamSelected.exam_data!,
            current_task: 0,
          },
        })
      );

      handleUpdateListStatusItems()
      
      dispatch(close_dialog())
      
      return;
    }

    ExamsServices.patchStudentExams(studentExamSelected.id!, STARTED_AT)
      .then(async () => {
        await dispatch(
          updateAssessmentState({
            student_exam: studentExamSelected.id!,
            exam: {
              ...studentExamSelected.exam_data!,
              current_task: 0,
            },
          })
        );

        handleUpdateListStatusItems()
      })
      .catch(() => alert("Ocorreu um erro."))
      .finally(() => dispatch(close_dialog()));
  };
};
