import React from "react";
import { LinearProgress, Container, Button, IconButton } from "@mui/material";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { Modal } from "components/Modal";

import starSvg from "assets/img/svg/star.svg";
import "./style.scss";

interface IProps {
  percentage: number;
  isOpen: boolean;
  onClose: () => void;
}

const AvaliacaoEnviada: React.FC<IProps> = ({ percentage, isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} title="" withAction={false} fullScreen onClose={onClose}>
      <Container maxWidth="sm">
        <div className="caModalAvaliacaoEnviada">
          <header className="title">
            <IconButton
              id="btn_arrow_back"
              color="primary"
              title="Retornar à página inicial"
              onClick={() => {onClose()}}
            >
              <ArrowBackIcon />
            </IconButton>
            <figure>
              <img src={starSvg} alt="" />
            </figure>
            <h1>Avaliação Enviada!</h1>
            <h3>Você preencheu {percentage.toFixed()}% da avaliação!</h3>
          </header>

          <main>
            <div>
              <LinearProgress
                sx={{ height: "12px", borderRadius: 10 }}
                id="progress_test"
                value={percentage}
                variant="determinate"
              />
            </div>
            <header>
              <h3>Agora, aguarde!</h3>
            </header>
            <section>
              <p>
                Um professor irá avaliar as respotas da sua avaliação e entregará
                o resultado em alguns dias e você receberá uma notificação.
              </p>
              <p>Fique Atento!</p>
            </section>
          </main>
          <footer>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {onClose()}}
            >
              RETORNAR À PÁGINA INICIAL
            </Button>
          </footer>
        </div>
      </Container>
    </Modal>
  );
};

export default AvaliacaoEnviada;
