import React from "react";
import Box from "@mui/material/Box";

import "./styles.scss";
import { getColorItem } from "./staticFunctions";
import { StatusItem } from "../../types";

interface IProps {
  status: StatusItem;
}

export const ItemStatus: React.FC<IProps> = ({ status }) => {
  return (
    <Box
      component="span"
      className="task-indicator-item-status"
      style={{ backgroundColor: getColorItem(status) }}
    />
  );
};
