import axios, { AxiosResponse } from "axios";
const DEFAULT_API = "https://api-platform.cenpe.ufc.br/";
const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL || DEFAULT_API,
});

/**
 * Setando o token global no header das requisições
 */
api.interceptors.request.use((config) => {
  let data: any = sessionStorage.getItem("@App:S");

  if (data) {
    data = JSON.parse(window.atob(data));
  }

  if (config.headers && !!data) {
    if (!!data) {
      config.headers["Authorization"] = `Bearer ${data.user.token}`;
    } else {
      config.headers["Authorization"] = "";
    }
  }

  return config;
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    let data: any = sessionStorage.getItem("@App:S");

    if (data) {
      data = JSON.parse(window.atob(data));
    }

    if (error.response.status === 401 && data.user.token) {
      const response = await refreshToken(error);
      return response;
    }
    return Promise.reject(error);
  }
);

/**
 * Implementa a chamada de refresh para quando o token expirar e a API responder com não autorizado
 */
async function refreshToken(error) {
  return new Promise((resolve, reject) => {
    try {
      let data: any = sessionStorage.getItem("@App:S");

      if (data) {
        data = JSON.parse(window.atob(data));
      }

      const refresh_token = data.user.refresh_token;
      const formData = new FormData();
      formData.append("grant_type", "refresh_token");
      formData.append("refresh_token", refresh_token);
      formData.append("client_id", process.env.REACT_APP_CLIENT_ID as string);
      formData.append(
        "client_secret",
        process.env.REACT_APP_CLIENT_SECRET as string
      );

      api
        .post("/oauth2/o/token/", formData)
        .then(async (response: AxiosResponse<any>) => {
          sessionStorage.setItem(
            "@App:S",
            window.btoa(
              JSON.stringify({
                ...data,
                token: response.data.access_token,
                refresh_token: response.data.refresh_token,
              })
            )
          );

          return resolve(response);
        })
        .catch((err) => {
          // Fazer algo caso o token de refresh não esteja OK
          return reject(error);
        });
    } catch (err) {
      return reject(err);
    }
  });
}
export default api;
