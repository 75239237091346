import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";

import LogoutIcon from "@mui/icons-material/Logout";

import logo from "assets/img/svg/logo.svg";

import { login } from "store/reducers/Authentication/Actions";
import { getIdUser } from "store/reducers/Authentication/AsyncActions";
import { setUserApplication } from "store/reducers/Application/actions";

import { ILoginProps } from "store/reducers/Authentication/Actions/types";
import { IRegisterProps } from "store/reducers/Authentication/AsyncActions/types";

import { WavySvg } from "assets/img/svg/WavySvg";
import AppsIcon from '@mui/icons-material/Apps';

import "./styles.scss";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { indigo } from "@mui/material/colors";
import { Lock, Visibility, VisibilityOff } from "@mui/icons-material";
import api from "services/api";
import { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { useAuth } from "@cenpe/auth";

export default function Appbar(): React.ReactElement {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const { logout: handleLogout } = useAuth.getState();
  const { user, userData } = useSelector((state: any) => state.authentication);

  const [initials, setInitials] = useState<any>("");
  const [passData, setPassData] = useState<any>({
    current_password: null,
    new_password: null,
  });

  const [showPassword, setShowPassoword] = useState<boolean>(false);
  const [showNewPassword, setShowNewPassoword] = useState<boolean>(false);
  const [enable, setEnable] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClickShowPassowd = () => {
    setShowPassoword(!showPassword);
  };

  const handleClickShowNewPassword = () => {
    setShowNewPassoword(!showNewPassword);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function logout() {
    sessionStorage.clear();
    handleLogout(true);
  }

  useEffect(() => {
    let appSession: any = sessionStorage.getItem("@App:S");
    if (appSession) {
      appSession = JSON.parse(window.atob(appSession));
      dispatch(login(appSession as ILoginProps));
      dispatch(getIdUser());
    }

    let appUserApplication: any = sessionStorage.getItem("@App:UApp");

    if (appUserApplication) {
      appUserApplication = JSON.parse(window.atob(appUserApplication));
      setInitials(appUserApplication.username.slice(0, 2));
      // alert(appUserApplication.username.slice(0, 2))
      dispatch(setUserApplication(appUserApplication as IRegisterProps));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionStorage.getItem("@App:S"), sessionStorage.getItem("@App:S")]);

  useEffect(() => {
    let appUserApplication: any = sessionStorage.getItem("@App:L");

    if (appUserApplication) {
      appUserApplication = JSON.parse(appUserApplication);
      setInitials(appUserApplication.username.slice(0, 2).toUpperCase());
    }
  }, [sessionStorage.getItem("@App:L")]);

  // if (!user.signed) {
  //   return <WavySvg />;
  // }

  const updatePassword = async () => {
    await api
      .post("/oauth2/users/change_password/", passData)
      .then((res: AxiosResponse<any>) => {
        toast.success("Senha atualizada com sucesso!");
        setOpenDialog(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error(
          err.response.data.current_password[0] &&
          "A senha atual informada está incorreta!"
        );
        toast.error(
          err.response.data.new_password[0] &&
          "A nova senha não pode ter menos que 8 caracteres!"
        );
      });
  };

  return (
    <>
      <AppBar
        position="static"
        elevation={0}
        style={{
          backgroundColor: "#fff",
          color: "#000",
        }}
      >
        <Toolbar className="header_page">
          <img
            src={logo}
            alt="Imagem de um hexagono com linhas trasadas no seu interior entre as vértices e com o nome CEnPE ao lado"
            style={{ width: 80 }}
          />

          <Box>

            <IconButton aria-label="delete" style={{ color: 'white' }} onClick={() => window.location.href = process.env.REACT_APP_SSO_URL || ''}>
              <AppsIcon />
            </IconButton>
            {user.signed && (
              <>
                <IconButton
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  <Avatar sx={{ bgcolor: 'var(--primary-regular)', color: 'white' }}>
                    {initials.toUpperCase()}
                  </Avatar>
                </IconButton>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem onClick={() => setOpenDialog(true)}>
                    Alterar senha
                  </MenuItem>
                  <MenuItem onClick={logout} href="/">
                    <LogoutIcon />
                    Logout
                  </MenuItem>
                </Menu>
              </>
            )}

            {user.signed && (
              <IconButton onClick={logout} href="/" title="Sair">
                <LogoutIcon style={{ color: 'white' }} />
              </IconButton>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Dialog
        open={openDialog}
        maxWidth="md"
        onClose={() => setOpenDialog(false)}
      >
        <DialogContent>
          <Box
            sx={{
              mb: 3,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Avatar sx={{ width: "56px", height: "56px", bgcolor: 'var(--primary-dark)' }}>
              <Lock />
            </Avatar>
          </Box>
          <Typography variant="h5">Alterar senha</Typography>
          <Typography variant="body1">
            Informe os dados solicitados e clique em alterar senha para
            prosseguir
          </Typography>

          <FormControl fullWidth sx={{ mb: 3, mt: 3 }}>
            <TextField
              type={showPassword ? "text" : "password"}
              label="Senha atual"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClickShowPassowd}>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                setPassData({ ...passData, current_password: e.target.value });
              }}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: 3 }}>
            <TextField
              type={showNewPassword ? "text" : "password"}
              label="Nova senha"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClickShowNewPassword}>
                      {showNewPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                if (e.target.value === passData.current_password) {
                  toast.warn(
                    "As senhas informadas são iguais! A nova senha deve ser diferente da senha atual."
                  );
                }
                setPassData({ ...passData, new_password: e.target.value });
              }}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: 3 }}>
            <TextField
              type="password"
              label="Confirmação"
              placeholder="Repita a senha informada para prosseguir"
              onChange={(e) => {
                if (e.target.value === passData.new_password) {
                  setEnable(true);
                  // toast.warn("As senhas informadas são iguais!")
                } else {
                  setEnable(false);
                }
              }}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={() => setOpenDialog(false)}>
            Cancelar
          </Button>
          <Button
            disabled={
              !(
                passData.current_password &&
                passData.new_password &&
                passData.new_password !== "" &&
                passData.current_password !== passData.new_password &&
                enable
              )
            }
            variant="contained"
            disableElevation
            onClick={updatePassword}
          >
            Alterar senha
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
