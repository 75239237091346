'use client'

import { useEffect, useState } from 'react';
import ReactMarkdown from "react-markdown"; // Componente pra renderizar markdown e os componentes personalizados
import remarkGfm from "remark-gfm"; // Plugin pra suporte a Github Flavored Markdown (GFM)
import remarkMath from "remark-math"; // Plugin pra suporte a expresões matemáticas
import rehypeKatex from "rehype-katex"; // Plugin pra renderizar as expressões matemáticas utilizando a biblioteca KaTeX
import remarkDirective from "remark-directive"; // Plugin pra suporte a diretivas personalizadas
import remarkDirectiveRehype from "remark-directive-rehype"; // Plugin pra transformar diretivas em elementos rehype


export function MarkComponent({ children }){

  return (
    <ReactMarkdown
      remarkPlugins={[
        remarkGfm,
        remarkMath,
        remarkDirective,
        remarkDirectiveRehype,
      ]}
      rehypePlugins={[rehypeKatex]}
    >
      {children}
    </ReactMarkdown>
  )
}