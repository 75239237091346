import { createAction } from "@reduxjs/toolkit";

import {IItem, ITaskProps} from './types';

// Actions para uma task

export const getTask = createAction<ITaskProps>('GET_TASK');
export const postTask = createAction<ITaskProps>('POST_TASK');
export const patchTask = createAction<ITaskProps>('PATCH_TASK');
export const deleteTask = createAction('DELETE_TASK');

// Actions para interagir com um item

export const getItem = createAction( 'GET_ITEM');
export const setItem = createAction<IItem>('SET_ITEM');
export const patchItem = createAction('PATCH_ITEM');
export const deleteItem = createAction('DELETE_ITEM');

