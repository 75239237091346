import {
    Box,
    Avatar,
    Typography
} from '@mui/material';

import { IItemDetailsOfDescription } from './types';

import './styles.scss';

export function ItemDetailsOfDescription(props: IItemDetailsOfDescription) {
    return (
        <Box className="item_info_evaluation">
          <Avatar className="area_icon_of_evaluation">
            {props.icon}
          </Avatar>

          <Box>
            <Typography className="info_title">
              {props.title}
            </Typography>

            <Typography className="describe_info_title">
              {props.description}
            </Typography>
          </Box>
        </Box>
    )
}