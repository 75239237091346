import { createAction } from "@reduxjs/toolkit";
import { StatusItem } from "pages/Assignment/AssignmentIndicatorTasks/types";
import { AssessmentResourceType } from "..";
import {
  IAssessmentExam,
  IAssessment,
  IExam,
  IStudentAnswer,
  IStudentExam,
  ITaskExam,
} from "./types";

/**
 * As ações para assessment são semelhantes as ações para CRUD
 */

export const getAssessmentExam = createAction<
  IAssessmentExam | number | string
>("GET_ASSESSMENT_EXAM");
export const sendAssessmentExam = createAction<IAssessmentExam>(
  "SEND_ASSESSMENT_EXAM"
);
export const fixAssessmentExam = createAction<IAssessmentExam>(
  "CLEAR_ASSESSMENT_EXAM"
);
export const clearAssessmentExam = createAction<number | string>(
  "FIX_ASSESSMENT_EXAM"
);

/**
 * As ações para assessment são semelhantes as ações para CRUD
 */

export const getAssessment = createAction<IAssessment | number | string>(
  "GET_ASSESSMENT"
);
export const showAssessment = createAction<IAssessment>("SHOW_ASSESSMENT");
export const sendAssessment = createAction<IAssessment>("SEND_ASSESSMENT");
export const fixAssessment = createAction<IAssessment>("CLEAR_ASSESSMENT");
export const clearAssessment = createAction<number | string>("FIX_ASSESSMENT");

/**
 * Ações para modificar um TasExam
 * */

export const fetchTaskExam = createAction<ITaskExam | number | string>(
  "GET_TASK_EXAM"
);
export const sendTaskExam = createAction<ITaskExam>("SEND_TASK_EXAM");
export const fixTaskExam = createAction<ITaskExam>("CLEAR_TASK_EXAM");
export const clearTaskExam = createAction<string | number>("FIX_TASK_EXAM");

/**
 * Ações para modificar um Exam
 * */
export const resetAssessmentState = createAction("RESET_ASSESSMENT_STATE");

export const updateAssessmentState = createAction<AssessmentResourceType>(
  "UPDATE_ASSESSMENT_STATE"
);
export const getExam = createAction("GET_EXAM");
export const getExams = createAction("GET_EXAMS");
export const getExamByIndex = createAction<any>("GET_EXAM_BY_INDEX");
export const getCurrentExamIndex = createAction<any>("GET_CURRENT_EXAM_INDEX");
export const setCurrentExamIndex = createAction<number>(
  "SET_CURRENT_EXAM_INDEX"
);
export const setExam = createAction<IExam>("SET_EXAM");
export const setExams = createAction<IExam[]>("SET_EXAMS");
export const getExamTasks = createAction<any>("GET_EXAM_TASKS");
export const getCurrentExamTask = createAction<number>(
  "GET_CURRENT_EXAM_TASKS"
);
export const setCurrentExamTask = createAction<number>(
  "SET_CURRENT_EXAM_TASKS"
);
export const updateExam = createAction<IExam>("UPDATE_EXAM");
export const deleteExam = createAction<string | number>("DELETE_EXAM");

/**
 * Ações para modificar um StudentExam
 * */

export const fetchStudentExam = createAction<IStudentExam | number | string>(
  "GET_STUDENT_EXAM"
);
export const sendStudentExam = createAction<IStudentExam>("SEND_STUDENT_EXAM");
export const fixStudentExam = createAction<IStudentExam>("CLEAR_STUDENT_EXAM");
export const clearStudentExam = createAction<string | number>(
  "FIX_STUDENT_EXAM"
);

/**
 * Ações desempenhadas pelo estudante ao responder uma prova
 * */

export const fetchStudentAnswers = createAction<
  IStudentAnswer | number | string
>("GET_STUDENT_ANSWER");
export const sendStudentAnswers = createAction<IStudentAnswer>(
  "SEND_STUDENT_ANSWER"
);
export const fixStudentAnswers = createAction<IStudentAnswer>(
  "CLEAR_STUDENT_ANSWER"
);
export const clearStudentAnswers = createAction<string | number>(
  "FIX_STUDENT_ANSWER"
);

export const updateItemStatus = createAction<{
  id: number;
  status: StatusItem;
}>("UPDATE_ITEM_STATUS");

export const updateListOfItemsStatus = createAction<number[]>("UPDATE_LIST_OF_ITEMS_STATUS");
