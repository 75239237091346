import { MarkdownRender } from "../markdownRender";

export function Dica({ text }) {
  return (
    <div
      className={`re-render comp flex items-center border-l-4 pl-2 mt-2 mb-2`}
      style={{ borderColor: "#3F26D9" }}
    >
      <MarkdownRender fit markdownCode={text} />
    </div>
  );
}
