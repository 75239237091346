import { createReducer } from '@reduxjs/toolkit';

import {
    deleteTask,
    getTask,
    patchTask,
    postTask,
    getItem,
    setItem,
    patchItem,
    deleteItem
} from './Actions';
import {ITaskProps} from "./Actions/types";

const INITIAL_VALUES: ITaskProps = {
    id: 0,
    created_by: '',
    created_at: '',
    title: '',
    content: '',
    items: [],
    status: 'idle'
}

export default createReducer(INITIAL_VALUES, {
    [getTask.type]: (state, action) => ({ ...state, ...action.payload }),
    [postTask.type]: (state, action) => ({ ...state, ...action.payload }),
    [patchTask.type]: (state, action) => ({ ...state, ...action.payload }),
    [deleteTask.type]: (state, action) => ({ ...INITIAL_VALUES }),
    [getItem.type]: (state, payload) => state.items!.find( item => item.id === payload.id),
    [setItem.type]: (state, action) => ({
        ...state,
        items: state.items!.map((item) => {
            return item.id === action.payload.id ? action.payload : item
        })
    }),
    [patchItem.type]: (state, action) => ({
        ...state,
        items: state.items!.map((item) => {
            return item.id !== action.payload.id ? item : {
                ...item,
                StatusItem: action.payload.StatusItem
            }
        })
    }),
    [deleteItem.type]: (state, action) => ({...state, ...action.payload})
});