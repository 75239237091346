import './styles.scss';

export function ListOfInstructions() {
    return (
        <ul className="list_of_instructions">
            <li>
                Você terá um tempo limite para realização da avaliação.
            </li>
            <li>Você pode finalizar a avaliação mesmo com algumas questões incompletas.</li>
            <li>A avaliação possui questões abertas e de multiplas escolhas.</li>
            <li>Responda a questão e clique em “Confirmar” para responder.</li>
            <li>Ao final da avaliação, clique em “Finalizar e Enviar” para terminar a prova caso você queira.</li>
        </ul>
    )
}