import React from "react";
import {Box, Button, Typography} from "@mui/material";

const NotFound: React.FC = () => { 
    return(
        <div className="container mt-5">
            <Box
                display={"flex"}
                alignItems={"center"}
                flexDirection={"column"}
                justifyContent={"space-between"}>
                <Typography variant={"h3"} style={{fontWeight: "bold"}}>404</Typography>
                <p style={{marginTop: "24px", marginBottom: "24px"}}>Nenhum conteúdo disponível no momento!</p>
                <Button onClick={()=>{
                    window.location.href = "/"
                }}>Retornar à navegação</Button>
            </Box>
        </div>
    );
}

export default NotFound;