import api from "services/api";
import { IStudentAnswer } from "../Actions/types";

// Services para interagir com a API no recurso de StudentAnswers

export const getStudentAnswers = async (): Promise<IStudentAnswer[]> => {
  return await api.get(`assessments/studentanswers/`);
};

export const postStudentAnswers = async (data: IStudentAnswer) => {
  return await api.post(`assessments/studentanswers/`, data);
};

export const getStudentAnswersById = async (
  id: number,
  exam_id: number
): Promise<any> => {
  return await api.get(
    `assessments/studentanswers/?item=${id}&student_exam=${exam_id}`
  );
};

export const updateStudentAnswers = async (
  id: string,
  data: IStudentAnswer
) => {
  return await api.patch(`assessments/studentanswers/${id}/`, data);
};

export const patchStudentAnswers = async (id: number, data: IStudentAnswer) => {
  return await api.patch(`assessments/studentanswers/${id}/`, data);
};

export const deleteStudentAnswers = async (id: number) => {
  return await api.delete(`assessments/studentanswers/${id}/`);
};
