import { createContext, useState } from "react";

export const RenderContext = createContext();

export function RenderProvider({ children }) {
  const [valoresObj, setValoresObj] = useState({});

  return (
    <RenderContext.Provider value={{ valoresObj, setValoresObj }}>{children}</RenderContext.Provider>
  )
}