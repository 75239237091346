import AuthenticationServices from "./authentication.services";

import { toast } from "react-toastify";

import { login, setUserData } from "../Actions";

import { IAuthProps, IRegisterProps } from "./types";

import api from "services/api";
import { setUserApplication } from "store/reducers/Application/actions";
import { AuthData } from "@cenpe/auth";

const getEstructureDataUser = (
  token: string,
  refresh_token: string,
  idUser: number
) => {
  return {
    user: {
      signed: true,
      token: token,
      refresh_token: refresh_token,
      idUser,
    },
  };
};

async function loadUserData({ data, dispatch }: any) {
  api.defaults!.headers!.Authorization = `Bearer ${data.access_token}`;
  // Armazena o refresh token
  sessionStorage.setItem("@App:R", data.refresh_token);

  await AuthenticationServices.getIdUser().then((response: any) => {
    const valuesLogin = getEstructureDataUser(
      data.access_token,
      data.refresh_token,
      response.data.id
    );

    sessionStorage.setItem(
      "@App:S",
      window.btoa(JSON.stringify({ ...valuesLogin }))
    );
    sessionStorage.setItem("@App:L", JSON.stringify(response.data));
    dispatch(login(valuesLogin));
    dispatch(setUserData(response.data));
    if (!isRoleTranscriber(response.data as IRegisterProps)) {
      dispatch(setUserApplication(response.data));
    }
  });
}

export const auth = (props: AuthData) => {
  return (dispatch: any) => {
    // const responseAuth = AuthenticationServices.authenticationUser(props).then(

    // );

    loadUserData({ data: props, dispatch });

    // toast.promise(responseAuth, {
    //   pending: "Estamos verificando suas credenciais...",
    //   success: "Bem-vindo e boas avaliações",
    //   error: "Identificador ou senha incorretos",
    // });
  };
};

const isRoleTranscriber = (param: IRegisterProps): boolean => {
  return (
    param.type === "teacher" ||
    param.type === "transcriber" ||
    param.type === "administrator"
  );
};

export const register = (props: IRegisterProps) => {
  return () => {
    AuthenticationServices.register(props)
      .then(() => {
        return {
          success: 1,
        };
      })
      .catch(() => {
        return {
          success: 0,
        };
      });
  };
};

export const getIdUser = () => {
  return (dispatch: any) => {
    AuthenticationServices.getIdUser().then((data: any) => {
      sessionStorage.setItem("@U:profile", JSON.stringify(data.data));
      dispatch(setUserData(data.data));
    });
  };
};
