import { createAction } from "@reduxjs/toolkit";
import { IStudentAnswer } from "./types";

export const getStudentAnswer     = createAction<number|string>('GET_STUDENT_ANSWER');
export const getStudentAnswerByItem = createAction<number|string>('GET_STUDENT_ANSWER_BY_ID');
export const getStudentAnswerById = createAction<number|string>('GET_STUDENT_ANSWER_BY_ID');
export const setStudentAnswer     = createAction<IStudentAnswer|IStudentAnswer[]>('SET_STUDENT_ANSWER');
export const addStudentAnswer     = createAction<IStudentAnswer>('ADD_STUDENT_ANSWER');
export const patchStudentAnswer   = createAction<IStudentAnswer>('PATCH_STUDENT_ANSWER');
export const deleteStudentAnswer  = createAction<number|string>('DELETE_STUDENT_ANSWER');
export const clearStudentAnswer   = createAction('CLEAR_STUDENT_ANSWER');
