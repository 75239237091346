import { configureStore } from "@reduxjs/toolkit";
import {
  authentication,
  assessments,
  tasks,
  exams,
  answers,
  modalsControl,
  loading,
  examStartController,
  application,
} from "./reducers";

export default configureStore({
  reducer: {
    answers,
    assessments,
    authentication,
    exams,
    loading,
    modalsControl,
    tasks,
    examStartController,
    application,
  },
});
