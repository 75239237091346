import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import './styles.scss';

export default function NoItems() {
    return (
        <Box className="area_no_items">
            <Typography className="txt_no_items">Nenhum item encontrado</Typography>
        </Box>
    )
}